import React from "react";
import { withRouter } from 'react-router-dom'
import Header from '../Header/Header';
let self;
class Terms extends React.Component {
    constructor(props) {
        super(props);
        self = this;
        this.state = {
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    onDeclineBtnClick = () => {
        this.props.history.push(`/index`);
    }
    onAcceptBtnClick = () => {
        sessionStorage.setItem("terms", true);
        let activeHead = localStorage.getItem("headTab");
        if (activeHead === null) {
            localStorage.setItem("headTab", "clinicaltrialresults");
            this.props.history.push('/clinicaltrialresults');
        } else {
            this.props.history.push('/' + activeHead);
        }
    }

    render() {
        return (
            <div className="main">
                <Header activeTabName={'terms'} />
                <div id="mainSection">
                    <div id="page">
                        <div className="section text-area">

                            <div className="container">
                                <h1>Purpose of Clinical Trial Results Website</h1>
                                <p className="para">The purpose of this clinical trial results website is to increase transparency of Novartis clinical trials by making objective, scientific information about those trials publicly available in a standard, summarized format. Clinical trials posted on this website are sponsored by Novartis and for more detailed information see the "Scope of Clinical Trial Results Website" section. </p>
                                <p className="para ">The nature and purpose of this clinical trial results website is strictly non-promotional.
                        </p>
                                <p className="para ">This clinical trial results website may contain information about clinical trials on products and/or indications which have not been approved in your country.</p>
                                <p className="para">The information in this clinical trial results website is not intended to be used as prescribing information, and should not be used as such.</p>
                                <p className="para">It has not been approved for this purpose under the applicable regulations.</p>
                                <p className="para">Members of the public should consult a healthcare professional for medical advice rather than relying on the information in this clinical trial results website to draw any conclusions about healthcare products, treatments or treatment
                                options.
                        </p>
                                <p className="para">This clinical trial results website is for informational purposes only and is not intended to have any legally binding effect.</p>
                                <h1 className="hilighted">Scope of Clinical Trial Results Website</h1>
                                <p className="para">This clinical trial results website contains information about clinical trials sponsored by Novartis. Please refer to Novartis' position on clinical trial data transparency for information on which clinical trial results are included
                            in this website.</p>
                                <p className="para">Information disclosed on the website for each trial includes detailed information on the trial design, specific trial efficacy endpoints and reported adverse events. As of May 2014, Novartis Pharmaceuticals began to add a synoptic
                                conclusion to phase 1-4 interventional results in patients. In January 2015, Alcon division began adding synoptic conclusions to their patient interventional trial results. Trial results are to be posted to the website within
                            one year of trial end.</p>

                                <p className="para">Although Novartis will endeavor in good faith to include in this clinical trial results website all clinical trials within the scope specified in the Novartis position on clinical trial data transparency, some trials may not appear
                                in the website if there are legitimate reasons to exclude them, for example, where there is an agreement with a third party which prevents Novartis from disclosing information about the trial, to allow filing of patent applications or
                            to comply with requirements of medical journals, or to comply with regulations governing the disclosure of information which is material to investors.</p>
                                <p className="para">Novartis will not be exercising any discretion to exclude results from the clinical trial results website on the basis of the results of the trial.</p>
                                <p className="para">Novartis will comply with its pharmacovigilance reporting obligations with respect to clinical trials, in accordance with applicable laws and regulations.</p>

                                <h1 className="hilighted">Terms and Conditions of Use</h1>
                                <p className="para">Your access to this clinical trial results website is subject to all applicable laws and regulations and to the following "Terms and Conditions of Use" governing the use of this website in general:</p>

                                <ol>
                                    <li>You must not rely on any information in this clinical trial results website as prescribing information, or otherwise, as the basis for any healthcare decision</li>
                                    <li>You should consult a healthcare professional for medical advice rather than relying on the information in this clinical trial results website to draw any conclusions about any products, treatments or treatment opinions.</li>
                                    <li>Novartis does not grant any right to use any information published in this clinical trial results website for commercial purposes, and you may not use the information for such purposes. "Commercial Purposes" shall include without
                                    limitation any use in connection with research and/or development programs which are aimed at bringing commercial products or services to market, and any use in connection with the provision of goods or services for valuable
                                    consideration.
                            </li>
                                    <li>Novartis does not grant any right to use the information published in this clinical trial results website for any regulatory purpose. In particular, and even though it is not considered that this clinical trial results website
                                    contains sufficient information for such purpose, you may not submit such information to any regulatory authority anywhere in the world seeking an authorization of any kind for any health care products.
                            </li>

                                </ol>
                                <p className="para">Click here to indicate that you agree to be bound by these Terms and conditions of use:</p>

                                <div className="button-section terms-buttons">
                                    <div className="read-more pull-right" id="termsDecline" onClick={() => self.onDeclineBtnClick()}>Decline</div>
                                    <div className="read-more pull-right right-gap" id="termsAccept" onClick={() => self.onAcceptBtnClick()}>I Accept</div>
                                    <div className="clear"></div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Terms);
