import React from "react";
import { withRouter, Link } from 'react-router-dom'
import Select from 'react-select';
import Header from '../Header/Header';
import Feedbackpop from '../Feedbackpop/Feedbackpop';
import novartislogo from './../../assets/novartis-logo.svg';
import * as Commonapirequest from '../Commonapirequest/Commonapirequest';
import * as Common from '../Common/Common';

let self;
const serverURL = Commonapirequest.getServerUrl();
class Productlist extends React.Component {
    constructor(props) {
        super(props);
        self = this;
        this.state = {
            filterFixed: false,
            filterFixedGoogleApply: false,
            relatedLinksPosition: 'related',
            googleTranslateRelatedPosition: false,
            selectListValue: null,
            selectedListOptions: [],
            showFeedbackPop: false,
            activeTitle: null,
            searchLoader: { class_name: 'loading', api_message: '', showloader: true },
            resultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            innerResultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            studyItemArray: [],
            productListObj: {},
            breadcrumbTitle: '',
            showContinuePop: false,
            continueLinkText: "",
            feedbackDivShow: false,
            selectTrialLanguageValue: null,
            selectPediatricLanguageValue: null,
            selectVideoValue: null,
            selectedTrialLanguageOptions: [],
            selectedPediatricLanguageOptions: [],
            selectedVideoOptions: [],
            trialLanguageError: false,
            pediatricLanguageError: false,
            feedbackGoogleClick: false,
            setTimer: false,
            videoLoader: { class_name: 'loading', api_message: '', showloader: true },
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        if (sessionStorage.getItem("terms") === null) {
            this.props.history.push(`/terms`);
            return false;
        }

        if (window.location.hash !== "") {

            let hash = (window.location.hash.split("?")[1])
            if (hash !== undefined) {
                let windowObj = (hash.slice(1).split('&').map(p => p.split('=')).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}))

                let serviceCAll = false;
                for (const [key, value] of Object.entries(windowObj)) {
                    if (key === undefined || value === undefined || value === "") {
                        serviceCAll = false;
                        self.setState({ searchLoader: { class_name: 'error', api_message: 'Internal application error', showloader: true } })
                    } else {
                        serviceCAll = true;
                    }
                }
                if (serviceCAll) {
                    self.onRenderCategoryData(windowObj);
                }
            } else {
                self.setState({ searchLoader: { class_name: 'error', api_message: 'Internal application error', showloader: true } })
            }

        } else {
            self.setState({ searchLoader: { class_name: 'error', api_message: 'Internal application error', showloader: true } })
        }

        window.addEventListener('scroll', self.windowScroll);


        window.addEventListener("resize", this.windowResize.bind(this));

        self.setTimerCount();

        
    }

    windowResize = () => {
        if (window.innerWidth < 1000) {
            self.setState({ filterFixed: false, filterFixedGoogleApply : false })
        }
    }

    setTimerCount = () => {
        this.countdown = setInterval(() => {
            this.timer()
        }, (5 * 60 * 1000));
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.windowScroll);
        clearInterval(this.countdown);
    }


    timer = () => {
        if (localStorage.getItem("designationId") !== null) {
            if (self.state.activeTitle === 'clinicaltrialresults' && (localStorage.getItem("ClinicalFeedback") === null || localStorage.getItem("ClinicalFeedback") === undefined || localStorage.getItem("ClinicalFeedback") === '')) {
                self.setState({
                    showFeedbackPop: true,
                    setTimer: true
                })
                document.body.classList.add('bodyActive');
            }
            if (self.state.activeTitle === 'trialsummariesforpatients' && (localStorage.getItem("SummaryFeedback") === null || localStorage.getItem("SummaryFeedback") === undefined || localStorage.getItem("SummaryFeedback") === '')) {
                self.setState({
                    showFeedbackPop: true,
                    setTimer: true
                })
                document.body.classList.add('bodyActive');
            }
        }

    }

    onRenderCategoryData = (windowObj) => {
        if (windowObj.ype === 'clinicalM' || windowObj.ype === 'clinicalP' || windowObj.ype === 'clinicalK') {
            self.setState({ activeTitle: 'clinicaltrialresults' }, () => {
                self.onLoadingHashData(windowObj);
            })
        } else if (windowObj.ype === 'trialM' || windowObj.ype === 'trialP' || windowObj.ype === 'trialK') {
            self.setState({ activeTitle: 'trialsummariesforpatients' }, () => {
                self.onLoadingHashData(windowObj);
            })
        }

    }

    onLoadingHashData = (windowObj) => {

        if (localStorage.getItem("designationId") !== null) {
            if (self.state.activeTitle === 'clinicaltrialresults' && (localStorage.getItem("ClinicalFeedback") === null || localStorage.getItem("ClinicalFeedback") === undefined || localStorage.getItem("ClinicalFeedback") === '')) {
                self.setState({ feedbackDivShow: true })
            }
            if (self.state.activeTitle === 'trialsummariesforpatients' && (localStorage.getItem("SummaryFeedback") === null || localStorage.getItem("SummaryFeedback") === undefined || localStorage.getItem("SummaryFeedback") === '')) {
                self.setState({ feedbackDivShow: true })
            }
        }

        if (windowObj.ype === "clinicalM" || windowObj.ype === "trialM") {
            self.onProductListDataLoading("Medical Condition", windowObj.medicalConditionId, windowObj.productId);
        } else if (windowObj.ype === "clinicalP" || windowObj.ype === "trialP") {
            self.onProductListDataLoading("Product", windowObj.medicalConditionId, windowObj.productId, windowObj.sorting);
        } else if (windowObj.ype === "clinicalK" || windowObj.ype === "trialK") {
            self.onProductListDataLoading("Keywords", windowObj.medicalConditionId, windowObj.KeywordId, windowObj.sponsor, windowObj.title);
        }
    }
    onErrorWindowHash = () => {
        self.setState({ searchLoader: { class_name: 'error', api_message: 'Internal application error', showloader: true } })
    }

    onProductListDataLoading = async (text, medicallIId, productId, alphabet, keyId) => {
        self.setState({ searchLoader: { class_name: 'loading', api_message: '', showloader: true,
    
        videoLoader: { class_name: 'loading', api_message: '', showloader: true }
     } })
        let setUrl = '';
        if (self.state.activeTitle === 'clinicaltrialresults') {
            if (text === "Medical Condition") {
                setUrl = `meidcalcondition/medicalconditions/ctr/all?medicalConditionId=${medicallIId}&k=${Commonapirequest.timeStamp()}`
            } else if (text === "Product") {
                setUrl = `product/products/ctr/all?productId=${productId}&k=${Commonapirequest.timeStamp()}`
            } else if (text === "Keywords") {
                setUrl = `search/searchresults/ctr?searchTerm=${productId}&k=${Commonapirequest.timeStamp()}`
            }
        } else {
            if (text === "Medical Condition") {
                setUrl = `meidcalcondition/medicalconditions/tsp/all?medicalConditionId=${medicallIId}&k=${Commonapirequest.timeStamp()}`
            } else if (text === "Product") {
                setUrl = setUrl = `product/products/tsp/all?productId=${productId}&k=${Commonapirequest.timeStamp()}`
            } else if (text === "Keywords") {
                setUrl = `search/searchresults/tsp?searchTerm=${productId}&k=${Commonapirequest.timeStamp()}`
            }
        }


        let clinicalListData = {
            url: setUrl,
            data: null,
            requestMethod: 'get',
        }
        let clinicalData = await Commonapirequest.makeApiRequest(clinicalListData);

        if (clinicalData) {
            if (clinicalData.status === 200) {
                if (clinicalData.data === "" || Object.keys(clinicalData.data).length === 0) {
                    self.setState({ searchLoader: { class_name: 'nodata', api_message: 'No results for your search criteria', showloader: true } })
                } else {
                    let Searchobj = []
                    let SearchValueObj = {}
                    if (text === "Medical Condition" || text === "Product") {
                        self.setState({ breadcrumbTitle: Object.keys(clinicalData.data) })
                        for (var i = 0; i < Object.values(clinicalData.data)[0].length; i++) {
                            let obj = (Object.values(clinicalData.data)[0][i])

                            Searchobj.push({
                                label: (text === "Medical Condition" ? obj.productGenricName : obj.medicalConditionName),
                                value: (text === "Medical Condition" ? obj.productGenricName : obj.medicalConditionName),
                                division: obj.division,
                                genericDrugName: obj.genericDrugName,
                                medicalConditionId: obj.medicalConditionId,
                                medicalConditionName: obj.medicalConditionName,
                                productGenricName: obj.productGenricName,
                                productId: obj.productId,
                                productName: obj.productName,
                            })

                            if (text === "Medical Condition" ? (obj.productId === Number(productId)) : (obj.medicalConditionId === Number(medicallIId))) {
                                SearchValueObj = {
                                    label: (text === "Medical Condition" ? obj.productGenricName : obj.medicalConditionName),
                                    value: (text === "Medical Condition" ? obj.productGenricName : obj.medicalConditionName),
                                    division: obj.division,
                                    genericDrugName: obj.genericDrugName,
                                    medicalConditionId: obj.medicalConditionId,
                                    medicalConditionName: obj.medicalConditionName,
                                    productGenricName: obj.productGenricName,
                                    productId: obj.productId,
                                    productName: obj.productName,
                                }
                            }
                        }

                    } else {
                        for (let [key, value] of Object.entries(clinicalData.data)) {
                            if (key === alphabet) {
                                for (let [medicalKey, medicalValue] of Object.entries(value)) {
                                    if (medicalKey.replace(/%20/g, " ") === keyId.replace(/%20/g, " ")) {
                                        self.setState({ breadcrumbTitle: medicalKey })
                                        for (var a = 0; a < medicalValue.length; a++) {
                                            if (medicalValue[a].medicalConditionId === Number(medicallIId)) {
                                                SearchValueObj = {
                                                    label: medicalValue[a].medicalConditionName,
                                                    value: medicalValue[a].medicalConditionName,
                                                    division: medicalValue[a].division,
                                                    genericDrugName: medicalValue[a].genericDrugName,
                                                    medicalConditionId: medicalValue[a].medicalConditionId,
                                                    medicalConditionName: medicalValue[a].medicalConditionName,
                                                    productGenricName: medicalValue[a].productGenricName,
                                                    productId: medicalValue[a].productId,
                                                    productName: medicalValue[a].productName,
                                                }
                                            }

                                            Searchobj.push({
                                                label: medicalValue[a].medicalConditionName,
                                                value: medicalValue[a].medicalConditionName,
                                                division: medicalValue[a].division,
                                                genericDrugName: medicalValue[a].genericDrugName,
                                                medicalConditionId: medicalValue[a].medicalConditionId,
                                                medicalConditionName: medicalValue[a].medicalConditionName,
                                                productGenricName: medicalValue[a].productGenricName,
                                                productId: medicalValue[a].productId,
                                                productName: medicalValue[a].productName,
                                            })
                                        }

                                    }
                                }
                            }
                        }

                    }
                    if (Object.keys(SearchValueObj).length > 0) {
                        self.setState({
                            selectedListOptions: Searchobj,
                            selectListValue: SearchValueObj,
                            searchLoader: { class_name: 'loading', api_message: '', showloader: false },
                            resultsLoader: { class_name: 'loading', api_message: '', showloader: true }
                        }, () => {
                            self.getSearchSuggestion()
                        })
                    } else {
                        self.setState({ searchLoader: { class_name: 'nodata', api_message: 'No results for your search criteria', showloader: true } })
                    }
                }

            } else {
                self.setState({ searchLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true } })
            }

        } else {
            self.setState({ searchLoader: { class_name: 'error', api_message: 'Network error', showloader: true } })
        }

    }

    windowScroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        if (window.innerWidth > 1024) {
            if(document.body.classList.value === "google_translate" && scrollTop > 95){
                self.setState({ filterFixed : false, filterFixedGoogleApply : true })
            }else if (scrollTop > 95) {
                self.setState({ filterFixed: true, filterFixedGoogleApply: false })
            } else {
                self.setState({ filterFixed: false, filterFixedGoogleApply: false, })
            }

        }
        if (window.innerWidth > 1000) {
            let scrollNo = 0;
            if (localStorage.getItem("designationId") === null) {
                scrollNo = 85
            } else if ((self.state.activeTitle === "clinicaltrialresults") && (localStorage.getItem("ClinicalFeedback") === null || localStorage.getItem("ClinicalFeedback") === undefined || localStorage.getItem("ClinicalFeedback") === "")) {
                scrollNo = 225
            } else if ((self.state.activeTitle === "trialsummariesforpatients") && (localStorage.getItem("SummaryFeedback") === null || localStorage.getItem("SummaryFeedback") === undefined || localStorage.getItem("SummaryFeedback") === "")) {
                scrollNo = 225
            } else {
                scrollNo = 85
            }
            if(document.body.classList.value === "google_translate"){
                if (scrollTop > scrollNo) {
                    self.setState({ googleTranslateRelatedPosition : true, relatedLinksPosition: 'related' })
                } else {
                    self.setState({ googleTranslateRelatedPosition : false, relatedLinksPosition: 'related' })
                }
                if (document.getElementById("breadcrumb").getBoundingClientRect() !== null && document.getElementById("breadcrumb").getBoundingClientRect().top < 545) {
                    self.setState({ relatedLinksPosition: 'absolute', googleTranslateRelatedPosition : false })
                }
            }else{
                if (scrollTop > scrollNo) {
                    self.setState({ relatedLinksPosition: 'fixed' })
                } else {
                    self.setState({ relatedLinksPosition: 'related' })
                }
                if (document.getElementById("breadcrumb").getBoundingClientRect() !== null && document.getElementById("breadcrumb").getBoundingClientRect().top < 500) {
                    self.setState({ relatedLinksPosition: 'absolute', googleTranslateRelatedPosition : false })
                }
            }

            
        } else {
            self.setState({ relatedLinksPosition: 'related', googleTranslateRelatedPosition : false })
        }
    }


    handleListChange = (newValue) => {
        let hash = (window.location.hash.split("?")[1])
        if (hash !== undefined) {
            let windowObj = (hash.slice(1).split('&').map(p => p.split('=')).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}))
            window.history.replaceState(null, null, ('#/product?type=' + windowObj.ype + '&medicalConditionId=' + newValue.medicalConditionId + '&productId=' + newValue.productId))
        }
        if (self.state.selectListValue !== null && (self.state.selectListValue.label !== newValue.label)) {
            self.setState({ selectListValue: newValue }, () => {
                self.getSearchSuggestion();
            })
        }
    }

    feedbackBtnClick = () => {
        self.setState({ showFeedbackPop: true });
        document.body.classList.add('bodyActive');
        document.body.classList.remove('google_translate');
    }
    onCancelFeedback = () => {
        clearInterval(this.countdown);
        self.setTimerCount();
        self.setState({ showFeedbackPop: false, feedbackGoogleClick: false });
        document.body.classList.remove('bodyActive');
        document.body.classList.remove('google');
    }

    getSearchSuggestion = async () => {
        self.setState({
            resultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            studyItemArray: []
        })
        let clinicalListData = {
            url: `meidcalcondition/medicalconditions/${self.state.activeTitle === 'clinicaltrialresults' ? 'ctr' : 'tsp'}/result?medicalConditionId=${self.state.selectListValue.medicalConditionId}&productId=${self.state.selectListValue.productId}&k=${Commonapirequest.timeStamp()}`,
            data: null,
            requestMethod: 'get',
        }
        let searchData = await Commonapirequest.makeApiRequest(clinicalListData);

        if (searchData) {
            if (searchData.status === 200) {
                if (searchData.data.length > 0) {
                    let StudyArray = [];
                    for (var i = 0; i < searchData.data.length; i++) {
                        StudyArray.push({
                            click: false,
                            id: searchData.data[i].id,
                            name: searchData.data[i].name,
                            value: searchData.data[i].name,
                            label: searchData.data[i].name
                        });
                    }
                    StudyArray[0].click = true;
                    self.setState({
                        studyItemArray: StudyArray,
                        resultsLoader: { class_name: 'loading', api_message: '', showloader: false }
                    }, () => {
                        self.productListDataCall(StudyArray[0]);
                    })
                } else {
                    self.setState({ resultsLoader: { class_name: 'nodata', api_message: 'No data available', showloader: true } })
                }
            } else {
                self.setState({
                    resultsLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true }
                })
            }
        } else {
            self.setState({
                resultsLoader: { class_name: 'error', api_message: 'Network error', showloader: true }
            })
        }
    }
    productListDataCall = async (obj) => {
        self.setState({
            productListObj: {},
            innerResultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            selectTrialLanguageValue: null,
            selectPediatricLanguageValue: null,
            selectVideoValue: null,
            selectedTrialLanguageOptions: [],
            selectedPediatricLanguageOptions: [],
            selectedVideoOptions: [],
            trialLanguageError: false,
            pediatricLanguageError: false,
        })

        let clinicalListData = {
            url: `trialresult/trialresults?trialResultId=${obj.id}&k=${Commonapirequest.timeStamp()}`,
            data: null,
            requestMethod: 'get',
        }
        let productData = await Commonapirequest.makeApiRequest(clinicalListData);

        if (productData) {
            if (productData.status === 200) {
                if (productData.data === "" || Object.keys(productData.data).length === 0) {
                    self.setState({ innerResultsLoader: { class_name: 'nodata', api_message: 'No data available', showloader: true } })
                } else {
                    let trialArray = [];
                    let pediatricArray = [];
                    let videoArray = [];
                    if (productData.data.trialSummaryForPatents !== null) {
                        for (var i = 0; i < productData.data.trialSummaryForPatents.length; i++) {
                            trialArray.push({
                                value: productData.data.trialSummaryForPatents[i].id,
                                label: productData.data.trialSummaryForPatents[i].languageName,
                                id: productData.data.trialSummaryForPatents[i].id,
                                languageId: productData.data.trialSummaryForPatents[i].languageId,
                                languageName: productData.data.trialSummaryForPatents[i].languageName,
                            })
                        }
                    }

                    if (productData.data.pediatricPatientLaySummary !== null) {
                        for (var j = 0; j < productData.data.pediatricPatientLaySummary.length; j++) {
                            pediatricArray.push({
                                value: productData.data.pediatricPatientLaySummary[j].id,
                                label: productData.data.pediatricPatientLaySummary[j].languageName,
                                id: productData.data.pediatricPatientLaySummary[j].id,
                                languageId: productData.data.pediatricPatientLaySummary[j].languageId,
                                languageName: productData.data.pediatricPatientLaySummary[j].languageName,
                            })
                        }
                    }

                    if(productData.data.videoSummariesForPediatricPatients !== null) {
                        for (var k = 0; k < productData.data.videoSummariesForPediatricPatients.length; k++) {
                            videoArray.push({
                                value: productData.data.videoSummariesForPediatricPatients[k].id,
                                label: productData.data.videoSummariesForPediatricPatients[k].languageName,
                                id: productData.data.videoSummariesForPediatricPatients[k].id,
                                languageId: productData.data.videoSummariesForPediatricPatients[k].languageId,
                                languageName: productData.data.videoSummariesForPediatricPatients[k].languageName,
                                videoLink:  productData.data.videoSummariesForPediatricPatients[k].videoLink,
                                videoName:  productData.data.videoSummariesForPediatricPatients[k].videoName,
                                videoSrc:  productData.data.videoSummariesForPediatricPatients[k].videoSrc,
                                id:  productData.data.videoSummariesForPediatricPatients[k].id,
                                videoStatus :  productData.data.videoSummariesForPediatricPatients[k].videoStatus
                            })
                        }
                       
                    }
                    if(videoArray.length > 0){                             
                        self.setState({ selectVideoValue : videoArray[0] })                            
                    }

                    self.setState({
                        productListObj: productData.data,
                        selectedTrialLanguageOptions: trialArray,
                        selectedPediatricLanguageOptions: pediatricArray,
                        selectedVideoOptions : videoArray,
                        innerResultsLoader: { class_name: 'loading', api_message: '', showloader: false }
                    },()=>{
                        if(videoArray.length > 0){                             
                            document.getElementById('videoBox').innerHTML = videoArray[0].videoName  
                            self.setState({
                                videoLoader: { class_name: 'loading', api_message: '', showloader: false 
                            }})                   
                        }
                        
                    })
                }

            } else {
                self.setState({
                    innerResultsLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true }
                })
            }

        } else {
            self.setState({
                innerResultsLoader: { class_name: 'error', api_message: 'Network error', showloader: true }
            })
        }
    }


    onAccordionClick = (text, data) => {
        let studyObj = self.state.studyItemArray;
        for (var i = 0; i < studyObj.length; i++) {
            studyObj[i].click = false
        }
        studyObj[text].click = studyObj[text].click === false ? true : false;
        self.setState({
            studyItemArray: studyObj,
            selectTrialLanguageValue: null,
            selectPediatricLanguageValue: null,
            selectVideoValue: null,
            selectedTrialLanguageOptions: [],
            selectedPediatricLanguageOptions: [],
            selectedVideoOptions: [],
            trialLanguageError: false,
            pediatricLanguageError: false,
        }, () => {
            self.productListDataCall(data);
        });
    }
    onHideAccordionClick = () => {
        let studyObj = self.state.studyItemArray;
        for (var i = 0; i < studyObj.length; i++) {
            studyObj[i].click = false
        }
        self.setState({ studyItemArray: studyObj });
    }

    onPDFBtnClick = (text, data) => {
        let url = "";
        if (text === "Technical") {
            url = serverURL + 'trialresult/trialresults/pdf?trialResultId=' + data.trialResultId;
        } else {
            url = serverURL + 'trialresult/journalsummary/pdf?trialResultId=' + data.trialResultId;
        }
        window.open(url, '_blank');
    }

    onContinueBtnClick = (text, data, tab) => {
        if (text) {
            document.body.classList.add('bodyActive');
        } else {
            document.body.classList.remove('bodyActive');
        }
        let url = '';
        if (tab === 'NCT') {
            url = `https://www.clinicaltrials.gov/ct2/show/NCT${data.nctNumber}?term=${data.technicalResultSummary}&rank=1`;
        } else if (tab === 'EudraCT') {
            url = `https://www.clinicaltrialsregister.eu/ctr-search/search?query=${data.eduraCtNumber}`;
        }

        self.setState({ showContinuePop: text, continueLinkText: url });
    }
    onMoveContinueBtnClick = () => {
        document.body.classList.remove('bodyActive');
        self.setState({ showContinuePop: false });
        window.open(self.state.continueLinkText, '_blank');
    }
    onRelatedLinkClick = (link) => {
        window.open(link, '_blank');
    }

    onSuccessFeedback = () => {
        self.setState({ feedbackDivShow: false })
    }

    handleTrialLanguageChange = (newValue) => {
        self.setState({ selectTrialLanguageValue: newValue, 
            pediatricLanguageError: false, 
            trialLanguageError: false
             })
    }

    handlePediatricLanguageChange = (newValue) => {
        self.setState({ selectPediatricLanguageValue: newValue,
            pediatricLanguageError: false, 
            trialLanguageError: false
         })
    }
    handleVideoLanguageChange = (newValue) => {
        document.getElementById('videoBox').innerHTML = ""  
        self.setState({ 
            selectVideoValue : newValue,             
            videoLoader: { class_name: 'loading', api_message: '', showloader: true },
            pediatricLanguageError: false, 
            trialLanguageError: false
        },()=>{
               let videoData = self.state.productListObj.videoSummariesForPediatricPatients
               for(var i=0;i<videoData.length;i++){
                   if(newValue.languageId === videoData[i].languageId){
                    document.getElementById('videoBox').innerHTML = videoData[i].videoName  
                    setTimeout(() => {
                        self.setState({ videoLoader: { class_name: 'loading', api_message: '', showloader: false } })
                    }, 200);
                    
                   }
               }
            })
    }

    TrialLanguageBtnClick = async () => {
        self.setState({ trialLanguageError: false })
        if (self.state.selectTrialLanguageValue === null) {
            self.setState({ trialLanguageError: true })
        } else {
            let url = serverURL + `patientsummary/patientsummaries?patientSummaryId=${self.state.selectTrialLanguageValue.value}`;
            window.open(url, '_blank');
        }
    }
    PediatricLanguageBtnClick = async () => {
        self.setState({ pediatricLanguageError: false })
        if (self.state.selectPediatricLanguageValue === null) {
            self.setState({ pediatricLanguageError: true })
        } else {
            let url = serverURL + `ppatientsummary/ppatientsummaries?periodicPatientSummaryId=${self.state.selectPediatricLanguageValue.value}`;
            window.open(url, '_blank');
        }
    }

    FeedbackClick = (e) => {
        self.setState({ feedbackGoogleClick: e })
    }

    onProvacyBtnClick = () => {
        self.setState({ showContinuePop: false })
        document.body.classList.remove('bodyActive');
        window.open("#/privacypolicy", "_blank");
    }

    render() {
        const {
            relatedLinksPosition,
            googleTranslateRelatedPosition,
            breadcrumbTitle,
            selectListValue,
            selectedListOptions,
            showFeedbackPop,
            setTimer,
            activeTitle,
            searchLoader,
            resultsLoader,
            productListObj,
            studyItemArray,
            innerResultsLoader,
            showContinuePop,
            feedbackDivShow,
            selectTrialLanguageValue,
            selectPediatricLanguageValue,
            selectVideoValue,
            selectedTrialLanguageOptions,
            selectedPediatricLanguageOptions,
            selectedVideoOptions,
            pediatricLanguageError,
            trialLanguageError,
            feedbackGoogleClick,
            filterFixed,
            filterFixedGoogleApply,
            videoLoader
        } = this.state
        return (
            <div className="main">
                <Header activeTabName={'productlist'}
                    feedbackGoogleClick={feedbackGoogleClick} />
                <div id="mainSection">
                    <div id="page">
                        <div className="section first-section">
                            <div className="container">

                                <div className="breadcrumb">
                                    <ol>
                                        <li><Link to="/index">Home</Link></li>
                                        <li><span> › </span> </li>
                                        {activeTitle === 'clinicaltrialresults' ? <li><Link to="/clinicaltrialresults"> Clinical Trial Results </Link></li> : <li><Link to="/trialsummariesforpatients"> Trial Summaries for Patients </Link></li>}

                                        <li><span> › </span> </li>
                                        <li className="active">{breadcrumbTitle}</li>
                                        <div className="clear"></div>
                                    </ol>
                                </div>

                                <div className="left-section pull-left">
                                    {searchLoader.showloader && <div className="productLoader">
                                        {Common.submitLoader(searchLoader)}
                                    </div>}
                                    <div className="title_gap"></div>
                                    <div className={filterFixed ? "search-form one-select fixed" : filterFixedGoogleApply ? "search-form one-select fixed google_fixed" : "search-form one-select"}>
                                        <div className="item select-item" style={{ zIndex: '2' }}>
                                            <Select
                                                value={selectListValue}
                                                onChange={this.handleListChange}
                                                options={selectedListOptions}
                                                className="select-box"
                                                placeholder={"Select Value"}
                                            />
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className={(filterFixed || filterFixedGoogleApply) ? "results_content fixed" : "results_content"}>

                                        <div className="results-div">

                                            {resultsLoader.showloader && Common.submitLoader(resultsLoader)}
                                            {(studyItemArray.length > 0) && <div>
                                                <div className="display-results">Displaying {studyItemArray.length} Results:</div>
                                                <div className="indication-title" id="clickText">{selectListValue === null ? "" : selectListValue.label}</div>
                                                {studyItemArray.length > 0 && <div className="accordion-box">
                                                    {studyItemArray.map((studyData, studyIndex) => (
                                                        <div className={studyData.click ? "accordion-item active" : "accordion-item"} key={"studyData" + studyIndex}>
                                                            <div className="accordion-head accordion-expand" onClick={studyData.click === false ? () => this.onAccordionClick(studyIndex, studyData) : () => this.onHideAccordionClick(studyIndex)}>
                                                                <div className="accordion-title" eventlabel={studyData.label}>{studyData.label}</div>
                                                            </div>
                                                            {studyData.click && <div className="accordion-body">

                                                                <div className="study-box product-study-box">
                                                                    {innerResultsLoader.showloader && Common.submitLoader(innerResultsLoader)}
                                                                    {(Object.keys(productListObj).length > 0) && <div className="study-box-inner">
                                                                        <div className="study-item">
                                                                            <p>Sponsor</p>
                                                                            <p>{productListObj.sponsor === null ? '--' : productListObj.sponsor}</p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        <div className="study-item">
                                                                            <p>Product code</p>
                                                                            <p>{productListObj.productName === null ? '--' : productListObj.productName}</p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        <div className="study-item">
                                                                            <p>Generic drug name</p>
                                                                            <p>{productListObj.genricDrugName === null ? '--' : productListObj.genricDrugName}</p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        <div className="study-item">
                                                                            <p>NCT number</p>
                                                                            <p>{productListObj.nctNumber === null ? '--' : <span onClick={() => self.onContinueBtnClick(true, productListObj, 'NCT')}>{productListObj.nctNumber}</span>}</p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        <div className="study-item">
                                                                            <p>EudraCT number</p>
                                                                            <p>{productListObj.eduraCtNumber === null ? '--' : <span onClick={() => self.onContinueBtnClick(true, productListObj, 'EudraCT')}>{productListObj.eduraCtNumber}</span>}</p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        <div className="study-item">
                                                                            <p>Medical condition</p>
                                                                            <p>{productListObj.medicalCondition === null ? '--' : productListObj.medicalCondition}</p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        <div className="study-item">
                                                                            <p>Protocol title</p>
                                                                            <p>{productListObj.protoclTitle === null ? '--' : productListObj.protoclTitle}</p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        <div className="study-item">
                                                                            <p eventlabel="Technical result summary">Technical result summary</p>
                                                                            <p className={productListObj.technicalResultSummary === null ? "" : "pdf"}> {productListObj.technicalResultSummary === null ? '--' : <span onClick={() => self.onPDFBtnClick("Technical", productListObj)}> {productListObj.technicalResultSummary} </span>} </p>
                                                                            <div className="clear"></div>
                                                                        </div>
                                                                        {productListObj.journalSummaryName !== null && <div className="study-item">
                                                                            <p eventlabel="Journal Summary for Patients">Journal Summary for Patients</p>
                                                                            <p className="pdf"><span onClick={() => self.onPDFBtnClick("Journal", productListObj)}> {productListObj.studyCode} </span> </p>
                                                                            <div className="clear"></div>
                                                                        </div>}

                                                                        {productListObj.trialSummaryForPatents !== null && <div className="study-item">
                                                                            <p eventlabel="Trial Summaries for Patients">Trial Summaries for Patients</p>
                                                                            <div className="study-item-select top" style={{ zIndex: 3 }}>
                                                                                <div className="search-select pull-left" style={{ zIndex: 3 }}>
                                                                                    <Select
                                                                                        value={selectTrialLanguageValue}
                                                                                        onChange={this.handleTrialLanguageChange}
                                                                                        options={selectedTrialLanguageOptions}
                                                                                        className="select-box"
                                                                                        placeholder={"Select Language"}
                                                                                        isClearable={true}
                                                                                    />
                                                                                    {trialLanguageError && <div className="minimum-error" style={{ zIndex: 1 }}> Please select language </div>}
                                                                                </div>
                                                                                <div className="icon pull-right" id="indicationSearchIcon" onClick={() => self.TrialLanguageBtnClick()}></div>
                                                                                <div className="clear"></div>

                                                                            </div>
                                                                            <div className="clear"></div>
                                                                        </div>}

                                                                        {productListObj.pediatricPatientLaySummary !== null && <div className="study-item">
                                                                            <p eventlabel="Pediatric Trial Summaries for Patients">Pediatric Trial Summaries for Patients</p>
                                                                            <div className="study-item-select" style={{ zIndex: 2 }}>
                                                                                <div className="search-select pull-left" style={{ zIndex: 2 }}>
                                                                                    <Select
                                                                                        value={selectPediatricLanguageValue}
                                                                                        onChange={this.handlePediatricLanguageChange}
                                                                                        options={selectedPediatricLanguageOptions}
                                                                                        className="select-box"
                                                                                        placeholder={"Select Language"}
                                                                                        isClearable={true}
                                                                                    />
                                                                                </div>
                                                                                {pediatricLanguageError && <div className="minimum-error"> Please select language </div>}
                                                                                <div className="icon pull-right" id="indicationSearchIcon" onClick={() => self.PediatricLanguageBtnClick()}></div>
                                                                                <div className="clear"></div>

                                                                            </div>
                                                                            
                                                                            <div className="clear"></div>
                                                                        </div>}

                                                                        {productListObj.videoSummariesForPediatricPatients !== null && <div className="study-item">
                                                                            <p eventlabel="Video Summaries for Pediatric Patients"> Video Summaries for Pediatric Patients </p>
                                                                            <div className="study-item-select" style={{ zIndex: 1 }}>
                                                                                <div className="search-select pull-left" style={{ zIndex: 1, width: '100%' }}>
                                                                                    <Select
                                                                                        value={selectVideoValue}
                                                                                        onChange={this.handleVideoLanguageChange}
                                                                                        options={selectedVideoOptions}
                                                                                        className="select-box"
                                                                                        placeholder={"Select Language"}
                                                                                        isClearable={false}
                                                                                    />
                                                                                </div>


                                                                                <div className="clear"></div>
                                                                                

                                                                            </div>
                                                                            <div className="clear"></div>
                                                                        
                                                                            <div className="video_box">
                                                                                {videoLoader.showloader && Common.submitLoader(videoLoader)}
                                                                                    <div id="videoBox"></div>
                                                                                     </div>
                                                                        </div>}


                                                                    </div>}
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    ))}


                                                </div>}
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="right-section pull-right">
                                    {feedbackDivShow && <div className="feedBack-box">
                                        <div className="center">
                                            <div className="feedBack-icon"> </div>
                                            <div className="feedBack-box-right">
                                                <p> Your Feedback is important to us. </p>

                                                <div className="read-more white animate_btn" id="feedBackBtn" onClick={() => self.feedbackBtnClick()}>
                                                    <p>Feedback</p>
                                                </div>

                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>}

                                    <div className={googleTranslateRelatedPosition ? "related-sec translate-fixed" : relatedLinksPosition === "related" ? "related-sec" : relatedLinksPosition === "fixed" ? "related-sec fixed" : "related-sec absolute"}>
                                        <div className="line full"></div>
                                        <h2 className="related-title">Related Links</h2>

                                        <div className="related-content">
                                            <ul>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartisclinicaltrials.com/TrialConnectWeb/whatisclinicaltrials.nov")}>
                                                        What are Clinical Trials? <span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartisclinicaltrials.com/TrialConnectWeb/benefits.nov")}>
                                                        Clinical Trial Benefits and Risks <span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartis.com/our-focus/patients-caregivers")}>
                                                        Our Focus/Patient and Caregivers <span></span> </p></li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://clinicaltrials.gov/")}>
                                                        ClinicalTrials.gov <span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.clinicaltrialsregister.eu/")}>EU Clinical
                                            Trials Register<span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartis.com/sites/www.novartis.com/files/clinical-trial-data-transparency.pdf")}>
                                                        Novartis Position on Clinical Trials Transparency<span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartis.com/sites/www.novartis.com/files/leaders-in-clinical-trial-data-transparency.pdf")}>Leaders
                                            in Clinical Trial Transparency <span></span> </p>
                                                </li>
                                                <li>
                                                    <Link to="/NovartisProductLibrary" target="_blank">Novartis Product Library <span></span> </Link>
                                                </li>

                                                <div className="clear"></div>
                                            </ul>

                                        </div>

                                    </div>

                                </div>

                                <div className="clear"></div>
                            </div>
                        </div>



                    </div>
                </div>
                <div className="breadcrumb outside" id="breadcrumb">
                    <div className="container">
                        <ol>
                            <li><Link to="/index">Home</Link></li>
                            <li><span> › </span> </li>
                            {activeTitle === 'clinicaltrialresults' ? <li><Link to="/clinicaltrialresults"> Clinical Trial Results </Link></li> : <li><Link to="/trialsummariesforpatients"> Trial Summaries for Patients </Link></li>}

                            <li><span> › </span> </li>
                            <li className="active">{breadcrumbTitle}</li>
                            <div className="clear"></div>
                        </ol>
                    </div>
                </div>

                {showFeedbackPop && <Feedbackpop
                    titleText={activeTitle === 'clinicaltrialresults' ? 'Clinical Trial Results' : 'Trial Summaries for Patients'}
                    setTimer={setTimer}
                    activeTitle={activeTitle}
                    FeedbackClick={self.FeedbackClick}
                    onCancelFeedback={self.onCancelFeedback}
                    onSuccessFeedback={self.onSuccessFeedback}
                />}


                {showContinuePop && <div className="popup" id="homePopup">
                    <div className="popup-overlay"></div>
                    <div className="popup-center">
                        <div className="popup-body">

                            <div className="popup-close pull-right" onClick={() => self.onContinueBtnClick(false)}></div>

                            <div className="popup-inner">
                                <div className="popup-logo"><img src={novartislogo} alt={""} /> </div>
                                <p className="popup-para">You are now leaving the novctrd.com. This link will take you to a website to which our <span onClick={() => self.onProvacyBtnClick()}> Privacy policy </span> does not apply. You are solely responsible for your interactions with that website.
                        </p>

                                <div className="popup-btn-sec">
                                    <div className="read-more right-gap pull-left" onClick={() => self.onMoveContinueBtnClick(false)}>Continue  </div>
                                    <div className="read-more pull-left hide-pop" onClick={() => self.onContinueBtnClick(false)}>Cancel</div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        )
    }
}

export default withRouter(Productlist);