import React from "react";
import { withRouter, Link } from 'react-router-dom'
import ReactGA from 'react-ga';
import Select from 'react-select';
import Header from '../Header/Header';
import Feedbackpop from '../Feedbackpop/Feedbackpop';
import novartislogo from './../../assets/novartis-logo.svg';
import * as Commonapirequest from '../Commonapirequest/Commonapirequest';
import * as Common from '../Common/Common';

import './inner.scss'

const serverURL = Commonapirequest.getServerUrl();
let self;
class Clinicaltrial extends React.Component {
    constructor(props) {
        super(props);
        self = this;
        this.state = {
            filterFixed: false,
            filterFixedGoogleApply: false,
            relatedLinksPosition: 'related',
            googleTranslateRelatedPosition: false,
            selectCategoryValue: { value: "Medical Condition", label: "Medical Condition", id: "MedicalCondition" },
            selectedCategoryOptions: [
                { value: "Medical Condition", label: "Medical Condition", id: "MedicalCondition" },
                { value: "Product", label: "Product", id: "Product" },
                { value: "Study Number", label: "Study Number", id: "StudyNumber" },
                { value: "Keywords", label: "Keywords", id: "Keywords" }
            ],
            selectSearchValue: null,
            selectedSearchOptions: [],
            showFeedbackPop: false,
            feedbackGoogleClick: false,
            resultsLoader: { class_name: 'loading', api_message: '', showloader: false },
            searchValueError: false,
            searchTextValue: '',
            showCloseBtn: false,
            searchResultsDisplayedHere: false,
            clinicalTrialDataObj: {},
            keywordsDataArray: [],
            showContinuePop: false,
            continueLinkText: "",
            feedbackDivShow: false,
            selectTrialLanguageValue: null,
            selectPediatricLanguageValue: null,            
            selectVideoValue: null,
            selectedTrialLanguageOptions: [],
            selectedPediatricLanguageOptions: [],
            selectedVideoOptions: [],
            trialLanguageError: false,
            pediatricLanguageError: false,
            searchLoadingState: false,
            searchNoDataState: false,
            studyItemArray: [],
            productListObj: {},
            innerResultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            setTimer: false,            
            videoLoader: { class_name: 'loading', api_message: '', showloader: true },
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        self.initializeReactGA();

        if (sessionStorage.getItem("terms") === null) {
            this.props.history.push(`/terms`);
            return false;
        }

        if (window.location.hash !== "") {

            let hash = (window.location.hash.split("?")[1])
            if (hash !== undefined) {
                let windowObj = (hash.slice(1).split('&').map(p => p.split('=')).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}))

                let serviceCAll = false;
                for (const [key, value] of Object.entries(windowObj)) {

                    if (key === undefined || value === undefined || value === "") {
                        serviceCAll = false;
                        self.setState({
                            resultsLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true },
                            selectSearchValue: null,
                            selectedSearchOptions: []
                        })
                    } else {
                        serviceCAll = true;
                    }
                }
                if (serviceCAll) {
                    self.onRenderCategoryData(windowObj);
                }

            } else {
                self.onSetMedicalWindow();
            }

        } else {
            self.onSetMedicalWindow();
        }

        if (localStorage.getItem("designationId") !== null && (localStorage.getItem("ClinicalFeedback") === null || localStorage.getItem("ClinicalFeedback") === undefined || localStorage.getItem("ClinicalFeedback") === '')) {
            self.setState({ feedbackDivShow: true })
        }

        window.addEventListener('scroll', self.windowScroll);

        window.addEventListener("resize", this.windowResize.bind(this));

        self.setTimerCount();

    }

    windowResize = () => {
        if (window.innerWidth < 1000) {
            self.setState({ filterFixed: false, filterFixedGoogleApply: false })
        }
    }

    setTimerCount = () => {
        this.countdown = setInterval(() => {
            this.timer()
        }, (5 * 60 * 1000));
    }



    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.windowScroll);
        clearInterval(this.countdown);
    }

    timer = () => {
        if (localStorage.getItem("designationId") !== null && (localStorage.getItem("ClinicalFeedback") === null || localStorage.getItem("ClinicalFeedback") === undefined || localStorage.getItem("ClinicalFeedback") === '')) {
            self.setState({
                showFeedbackPop: true,
                setTimer: true
            });
            document.body.classList.add('bodyActive');
        }
    }

    initializeReactGA = () => {
        let reactGaLink = process.env.REACT_APP_INITIALIZEGA;
        ReactGA.initialize(reactGaLink);
        ReactGA.pageview('/clinicaltrialresults');
    }


    onRenderCategoryData = (windowObj) => {
        if (windowObj.ategory === 'MedicalCondition') {
            if (windowObj.searchId === undefined) {
                self.setState({
                    selectCategoryValue: { value: "Medical Condition", label: "Medical Condition", id: "MedicalCondition" },
                }, () => {
                    self.ClinicalTrialsDataLoad();
                })
            } else {
                if (windowObj.searchId === undefined || windowObj.searchName === undefined) {
                    self.onSetMedicalWindow();
                } else {
                    self.setState({
                        selectCategoryValue: { value: "Medical Condition", label: "Medical Condition", id: "MedicalCondition" },
                        selectSearchValue: { id: windowObj.searchId, value: windowObj.searchId, name: (windowObj.searchName.replace(/%20/g, " ")), label: (windowObj.searchName.replace(/%20/g, " ")) }
                    }, () => {
                        self.getSearchSuggestion(windowObj.searchName);
                        self.ClinicalTrialsDataLoad();
                    })
                }

            }
        } else if (windowObj.ategory === 'Product') {
            if (windowObj.searchId === undefined) {
                self.setState({
                    selectCategoryValue: { value: "Product", label: "Product", id: "Product" },
                }, () => {
                    self.ClinicalTrialsDataLoad();
                })
            } else {
                if (windowObj.searchId === undefined || windowObj.searchName === undefined) {
                    self.onSetMedicalWindow();
                } else {
                    self.setState({
                        selectCategoryValue: { value: "Product", label: "Product", id: "Product" },
                        selectSearchValue: { id: windowObj.searchId, value: windowObj.searchId, name: (windowObj.searchName.replace(/%20/g, " ")), label: (windowObj.searchName.replace(/%20/g, " ")) },

                    }, () => {
                        self.getSearchSuggestion(windowObj.searchName);
                        self.ClinicalTrialsDataLoad();
                    })
                }
            }
        } else if (windowObj.ategory === 'StudyNumber') {
            if (windowObj.searchId === undefined) {
                self.setState({
                    selectCategoryValue: { value: "Study Number", label: "Study Number", id: "StudyNumber" },
                }, () => {
                    self.ClinicalTrialsDataLoad();
                })
            } else {
                if (windowObj.searchId === undefined || windowObj.searchName === undefined) {
                    self.onSetMedicalWindow();
                } else {
                    self.setState({
                        selectCategoryValue: { value: "Study Number", label: "Study Number", id: "StudyNumber" },
                        selectSearchValue: { id: windowObj.searchId, value: windowObj.searchId, name: (windowObj.searchName.replace(/%20/g, " ")), label: (windowObj.searchName.replace(/%20/g, " ")) }
                    }, () => {
                        self.getSearchSuggestion(windowObj.searchName);
                        self.ClinicalTrialsDataLoad();
                    })
                }
            }
        } else if (windowObj.ategory === 'Keywords') {
            if (windowObj.searchId === undefined) {
                self.setState({
                    searchResultsDisplayedHere: true,
                    selectCategoryValue: { value: "Keywords", label: "Keywords", id: "Keywords" },
                })
            } else {
                if (windowObj.searchId === undefined) {
                    self.onSetMedicalWindow();
                } else {
                    self.setState({
                        selectCategoryValue: { value: "Keywords", label: "Keywords", id: "Keywords" },
                        selectSearchValue: { id: windowObj.searchId, value: windowObj.searchId, name: (windowObj.searchId.replace(/%20/g, " ")), label: (windowObj.searchId.replace(/%20/g, " ")) },
                        searchResultsDisplayedHere: false,
                    }, () => {
                        self.getSearchSuggestion(windowObj.searchId);
                        self.ClinicalTrialsDataLoad();
                    })
                }
            }
        } else {
            this.props.history.push(`/Pagenotfound`);
            window.location.hash = `/Pagenotfound`;
        }
    }


    onSetMedicalWindow = () => {
        window.history.replaceState(null, null, ('#/clinicaltrialresults?category=MedicalCondition'))
        self.setState({
            selectCategoryValue: { value: "Medical Condition", label: "Medical Condition", id: "MedicalCondition" },
            selectSearchValue: null,
            selectedSearchOptions: []
        }, () => {
            self.ClinicalTrialsDataLoad();
        })
    }

    windowScroll = () => {
        let scrollTop = document.documentElement.scrollTop;

        if (window.innerWidth > 1024) {
            if(document.body.classList.value === "google_translate" && scrollTop > 220){
                self.setState({ filterFixed : false, filterFixedGoogleApply : true })
            }else if (scrollTop > 220) {
                self.setState({ filterFixed: true, filterFixedGoogleApply: false })
            } else {
                self.setState({ filterFixed: false, filterFixedGoogleApply: false, })
            }

        }
        if (window.innerWidth > 1000) {
            if(document.body.classList.value === "google_translate"){
                if (localStorage.getItem("designationId") === null && (scrollTop > 85)) {
                    self.setState({ googleTranslateRelatedPosition : true, relatedLinksPosition: 'related' })
                } else if (scrollTop > ((localStorage.getItem("ClinicalFeedback") === null || localStorage.getItem("ClinicalFeedback") === undefined || localStorage.getItem("ClinicalFeedback") === "") ? 225 : 85)) {
                    self.setState({ googleTranslateRelatedPosition : true, relatedLinksPosition: 'related' })
                } else{
                    self.setState({ googleTranslateRelatedPosition : false, relatedLinksPosition: 'related' })
                }

                if (document.getElementById("breadcrumb").getBoundingClientRect() !== null && document.getElementById("breadcrumb").getBoundingClientRect().top < 545) {
                    self.setState({ relatedLinksPosition: 'absolute', googleTranslateRelatedPosition : false })
                }
            }else{
                self.setState({googleTranslateRelatedPosition : false})
                if (localStorage.getItem("designationId") === null && (scrollTop > 85)) {
                    self.setState({ relatedLinksPosition: 'fixed' })
                } else if (scrollTop > ((localStorage.getItem("ClinicalFeedback") === null || localStorage.getItem("ClinicalFeedback") === undefined || localStorage.getItem("ClinicalFeedback") === "") ? 225 : 85)) {
                    self.setState({ relatedLinksPosition: 'fixed' })
                } else {
                    self.setState({ relatedLinksPosition: 'related' })
                }

                if (document.getElementById("breadcrumb").getBoundingClientRect() !== null && document.getElementById("breadcrumb").getBoundingClientRect().top < 500) {
                    self.setState({ relatedLinksPosition: 'absolute', googleTranslateRelatedPosition : false })
                }
            }            
            
        } else {
            self.setState({ relatedLinksPosition: 'related', googleTranslateRelatedPosition : false })
        }
    }

    formatCreate = (inputValue) => {
        return (<p>{inputValue}</p>);
    };

    feedbackBtnClick = () => {
        self.setState({ showFeedbackPop: true });
        document.body.classList.add('bodyActive');
        document.body.classList.remove('google_translate');
    }
    onCancelFeedback = () => {
        clearInterval(this.countdown);
        self.setTimerCount();
        self.setState({ showFeedbackPop: false, feedbackGoogleClick: false });
        document.body.classList.remove('bodyActive');
        document.body.classList.remove('google');
    }
    FeedbackClick = (e) => {
        self.setState({ feedbackGoogleClick: e })
    }

    onSuccessFeedback = () => {
        self.setState({ feedbackDivShow: false })
    }


    onSearchEnterKey = (e) => {
        if (e.keyCode === 13) {
            if (self.state.searchTextValue.length < 3) {
                self.setState({ searchValueError: true });
                setTimeout(() => {
                    self.setState({ searchValueError: false });
                }, 2000);
            } else {
                self.setState({ currentPage: 1, showCloseBtn: true }, () => {
                    this.ClinicalTrialsDataLoad();
                });
            }
        }
    }

    onChangeSearchText = (e) => {
        self.setState({
            searchTextValue: e.target.value,
            searchValueError: false
        });
    }

    onSearchIconClick = () => {
        if (self.state.selectSearchValue === null) {
            self.setState({ searchValueError: true })
        } else {
            if (self.state.selectCategoryValue.label === "Keywords") {
                window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + self.state.selectCategoryValue.id) + ("&searchId=" + self.state.selectSearchValue.id))
            } else {
                window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + self.state.selectCategoryValue.id) + ("&searchId=" + self.state.selectSearchValue.id) + ('&searchName=' + self.state.selectSearchValue.label))
            }
            self.ClinicalTrialsDataLoad();
        }
    }


    handleCategoryChange = (newValue) => {

        window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + newValue.id))

        if (newValue.label === 'Keywords') {
            self.setState({ searchResultsDisplayedHere: true })
        } else {
            self.setState({ searchResultsDisplayedHere: false })
        }
        if (self.state.selectCategoryValue.label !== newValue.label) {
            self.setState({
                selectCategoryValue: newValue,
                searchTextValue: "",
                searchValueError: false,
                selectSearchValue: null,
                selectedSearchOptions: [],
                clinicalTrialDataObj: {},
                keywordsDataArray: [],
                resultsLoader: { class_name: 'loading', api_message: '', showloader: false }
            }, () => {
                if (newValue.label === 'Medical Condition' || newValue.label === 'Product' || newValue.label === 'Study Number') {
                    self.ClinicalTrialsDataLoad();
                }
            })
        }
    }
    handleSearchChange = (newValue) => {
        self.setState({ searchValueError: false })
        if (newValue !== null) {
            self.setState({ selectSearchValue: newValue }, () => {
                self.getSearchSuggestion(newValue.label);
            })
        } else {
            self.setState({ selectSearchValue: null, selectedSearchOptions: [] }, () => {
                if (self.state.selectCategoryValue.label === 'Medical Condition' || self.state.selectCategoryValue.label === 'Product' || self.state.selectCategoryValue.label === 'Study Number') {
                    self.ClinicalTrialsDataLoad();
                } else {
                    self.setState({
                        clinicalTrialDataObj: {},
                        keywordsDataArray: [],
                        searchResultsDisplayedHere: true
                    })
                }
            })
        }

        window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + self.state.selectCategoryValue.id))

    }

    handleInputChange = () => {
        if (self.state.selectSearchValue === null) {
            self.setState({
                selectedSearchOptions: [],
                searchNoDataState: false
            })
        }

    }

    onInputKeyDown = (e) => {
        self.setState({ searchValueError: false });
        let abc = e.target
        setTimeout(() => {
            let searchText = abc.value
            if (searchText.length >= 3) {
                self.getSearchSuggestion(searchText);
            } else {
                self.setState({
                    selectedSearchOptions: [],
                    selectSearchValue: null,
                    searchLoadingState: false,
                    searchNoDataState: false,
                })
            }
        }, 1)
    }

    getSearchSuggestion = async (value) => {
        self.setState({
            searchLoadingState: true,
            searchNoDataState: false,
            selectedSearchOptions: [],
            //studyItemArray: []
        })
        let setUrl = '';
        if (self.state.selectCategoryValue.label === 'Medical Condition') {
            setUrl = `meidcalcondition/medicalconditions/ctr?searchTerm=${value}&k=${Commonapirequest.timeStamp()}`
        } else if (self.state.selectCategoryValue.label === 'Product') {
            setUrl = `product/products/ctr?searchTerm=${value}&k=${Commonapirequest.timeStamp()}`
        } else if (self.state.selectCategoryValue.label === 'Study Number') {
            setUrl = `study/studies/ctr?searchTerm=${value}&k=${Commonapirequest.timeStamp()}`
        } else if (self.state.selectCategoryValue.label === 'Keywords') {
            setUrl = `search/suggestion/ctr?searchTerm=${value}&k=${Commonapirequest.timeStamp()}`
        }

        let clinicalListData = {
            url: setUrl,
            data: null,
            requestMethod: 'get',
        }
        let searchData = await Commonapirequest.makeApiRequest(clinicalListData);
        if (searchData) {
            if (searchData.status === 200) {
                self.setState({ searchLoadingState: false });
                if (searchData.data.length > 0) {
                    let SearchArray = [];
                    for (var i = 0; i < searchData.data.length; i++) {
                        if (self.state.selectCategoryValue.label === 'Medical Condition' || self.state.selectCategoryValue.label === 'Product' || self.state.selectCategoryValue.label === 'Study Number') {
                            SearchArray.push({
                                id: searchData.data[i].id,
                                name: searchData.data[i].name,
                                value: searchData.data[i].name,
                                label: searchData.data[i].name
                            })
                        } else if (self.state.selectCategoryValue.label === 'Keywords') {
                            SearchArray.push({
                                id: searchData.data[i],
                                name: searchData.data[i],
                                value: searchData.data[i],
                                label: searchData.data[i]
                            })
                        }
                    }
                    self.setState({ selectedSearchOptions: SearchArray })
                } else {
                    self.setState({ searchNoDataState: true });
                }

            }

        }
    }
    ClinicalTrialsDataLoad = async () => {
        self.setState({
            resultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            searchResultsDisplayedHere: false,
            clinicalTrialDataObj: {},
            keywordsDataArray: [],
            selectTrialLanguageValue: null,
            selectPediatricLanguageValue: null,
            selectVideoValue: null,
            selectedTrialLanguageOptions: [],
            selectedPediatricLanguageOptions: [],
            selectedVideoOptions: [],
            trialLanguageError: false,
            pediatricLanguageError: false,
        })

        let setUrl = '';
        if (self.state.selectCategoryValue.label === 'Medical Condition') {
            if (self.state.selectSearchValue === null) {
                setUrl = `meidcalcondition/medicalconditions/ctr/all?k=${Commonapirequest.timeStamp()}`
            } else {
                setUrl = `meidcalcondition/medicalconditions/ctr/all?medicalConditionId=${self.state.selectSearchValue.id}&k=${Commonapirequest.timeStamp()}`
            }
        } else if (self.state.selectCategoryValue.label === 'Product') {
            if (self.state.selectSearchValue === null) {
                setUrl = `product/products/ctr/all?k=${Commonapirequest.timeStamp()}`
            } else {
                setUrl = `product/products/ctr/all?productId=${self.state.selectSearchValue.id}&k=${Commonapirequest.timeStamp()}`
            }
        } else if (self.state.selectCategoryValue.label === 'Study Number') {
            if (self.state.selectSearchValue === null) {
                setUrl = `study/studies/ctr?k=${Commonapirequest.timeStamp()}`
            } else {
                setUrl = `study/studies/ctr?searchTerm=${self.state.selectSearchValue.label}&k=${Commonapirequest.timeStamp()}`
            }

        } else if (self.state.selectCategoryValue.label === 'Keywords') {
            setUrl = `search/searchresults/ctr?searchTerm=${self.state.selectSearchValue.id}&k=${Commonapirequest.timeStamp()}`
        }

        let clinicalListData = {
            url: setUrl,
            data: null,
            requestMethod: 'get',
        }
        let clinicalData = await Commonapirequest.makeApiRequest(clinicalListData);
        if (clinicalData) {
            if (clinicalData.status === 200) {
                if (self.state.selectCategoryValue.label === 'Medical Condition' || self.state.selectCategoryValue.label === 'Product') {
                    if (Object.keys(clinicalData.data).length > 0) {
                        for (var i = 0; i < Object.values(clinicalData.data).length; i++) {
                            for (var j = 0; j < Object.values(clinicalData.data)[i].length; j++) {
                                Object.values(clinicalData.data)[i][j].click = false;
                            }
                        }

                        let hash = (window.location.hash.split("?")[1])
                        if (hash !== undefined) {
                            let windowObj = (hash.slice(1).split('&').map(p => p.split('=')).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}))
                            if (windowObj.title === undefined) {
                                for (var k = 0; k < Object.values(clinicalData.data)[0].length; k++) {
                                    Object.values(clinicalData.data)[0][k].click = true
                                }
                            } else {
                                for (const [key, value] of Object.entries(clinicalData.data)) {
                                    if (windowObj.title === (key.split(' ').join(''))) {
                                        for (var d = 0; d < value.length; d++) {
                                            value[d].click = true;
                                        }
                                        Common.goToViolation(windowObj.title)
                                    }
                                }
                            }
                        }

                        self.setState({
                            clinicalTrialDataObj: clinicalData.data,
                            resultsLoader: { class_name: 'loading', api_message: '', showloader: false }
                        })
                    } else {
                        self.setState({
                            resultsLoader: { class_name: 'nodata', api_message: 'No data available', showloader: true }
                        })
                    }
                } else if (self.state.selectCategoryValue.label === 'Study Number') {

                    if (clinicalData.data.length > 0) {
                        let StudyArray = [];
                        let studyObject = {};
                        for (var s = 0; s < clinicalData.data.length; s++) {
                            StudyArray.push({
                                click: false,
                                id: clinicalData.data[s].id,
                                name: clinicalData.data[s].name,
                                value: clinicalData.data[s].name,
                                label: clinicalData.data[s].name
                            });
                        }

                        let hash = (window.location.hash.split("?")[1])
                        if (hash !== undefined) {
                            let windowObj = (hash.slice(1).split('&').map(p => p.split('=')).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}))
                            if (windowObj.title === undefined) {
                                StudyArray[0].click = true;
                                studyObject = StudyArray[0]
                            } else {
                                for (var t = 0; t < StudyArray.length; t++) {
                                    if (windowObj.title === (StudyArray[t].name.split(' ').join(''))) {
                                        StudyArray[t].click = true;
                                        studyObject = StudyArray[t]
                                    }
                                }
                                Common.goToViolation(windowObj.title)
                            }
                        }

                        self.setState({
                            studyItemArray: StudyArray,
                            resultsLoader: { class_name: 'loading', api_message: '', showloader: false }
                        }, () => {
                            self.productListDataCall(studyObject);
                        })
                    }
                } else if (self.state.selectCategoryValue.label === 'Keywords') {
                    let keyWordsArray = [];
                    for (let [key, value] of Object.entries(clinicalData.data)) {
                        let medicalArray = [];
                        for (let [medicalKey, medicalValue] of Object.entries(value)) {
                            let medicalObj = {
                                click: false,
                                medicalName: medicalKey,
                                medicalValue: medicalValue,
                            }
                            medicalArray.push(medicalObj)
                        }
                        medicalArray[0].click = true;
                        let obj = {
                            click: false,
                            sponsor: key,
                            medical: medicalArray,
                        }
                        keyWordsArray.push(obj);
                    }
                    keyWordsArray[0].click = true;

                    self.setState({
                        keywordsDataArray: keyWordsArray,
                        resultsLoader: { class_name: 'loading', api_message: '', showloader: false }
                    })
                }

            } else {
                self.setState({
                    resultsLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true }
                })
            }

        } else {
            self.setState({
                resultsLoader: { class_name: 'error', api_message: 'Network error', showloader: true }
            })
        }
    }



    productListDataCall = async (obj) => {
        self.setState({
            productListObj: {},
            innerResultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            selectTrialLanguageValue: null,
            selectPediatricLanguageValue: null,
            selectVideoValue: null,
            selectedTrialLanguageOptions: [],
            selectedPediatricLanguageOptions: [],
            selectedVideoOptions: [],
            trialLanguageError: false,
            pediatricLanguageError: false,            
            videoLoader: { class_name: 'loading', api_message: '', showloader: true }
        });

        let clinicalListData = {
            url: `trialresult/trialresults?trialResultId=${obj.id}&k=${Commonapirequest.timeStamp()}`,
            data: null,
            requestMethod: 'get',
        }
        let productData = await Commonapirequest.makeApiRequest(clinicalListData);

        if (productData) {
            if (productData.status === 200) {
                if (productData.data === "" || Object.keys(productData.data).length === 0) {
                    self.setState({ innerResultsLoader: { class_name: 'nodata', api_message: 'No data available', showloader: true } })
                } else {
                    let trialArray = [];
                    let pediatricArray = [];
                    let videoArray= [];
                    if (productData.data.trialSummaryForPatents !== null) {
                        for (var i = 0; i < productData.data.trialSummaryForPatents.length; i++) {
                            trialArray.push({
                                value: productData.data.trialSummaryForPatents[i].id,
                                label: productData.data.trialSummaryForPatents[i].languageName,
                                id: productData.data.trialSummaryForPatents[i].id,
                                languageId: productData.data.trialSummaryForPatents[i].languageId,
                                languageName: productData.data.trialSummaryForPatents[i].languageName,
                            })
                        }
                    }

                    if (productData.data.pediatricPatientLaySummary !== null) {
                        for (var j = 0; j < productData.data.pediatricPatientLaySummary.length; j++) {
                            pediatricArray.push({
                                value: productData.data.pediatricPatientLaySummary[j].id,
                                label: productData.data.pediatricPatientLaySummary[j].languageName,
                                id: productData.data.pediatricPatientLaySummary[j].id,
                                languageId: productData.data.pediatricPatientLaySummary[j].languageId,
                                languageName: productData.data.pediatricPatientLaySummary[j].languageName,
                            })
                        }
                    }

                    if(productData.data.videoSummariesForPediatricPatients !== null) {
                        for (var k = 0; k < productData.data.videoSummariesForPediatricPatients.length; k++) {
                            videoArray.push({
                                value: productData.data.videoSummariesForPediatricPatients[k].id,
                                label: productData.data.videoSummariesForPediatricPatients[k].languageName,
                                id: productData.data.videoSummariesForPediatricPatients[k].id,
                                languageId: productData.data.videoSummariesForPediatricPatients[k].languageId,
                                languageName: productData.data.videoSummariesForPediatricPatients[k].languageName,
                                videoLink:  productData.data.videoSummariesForPediatricPatients[k].videoLink,
                                videoName:  productData.data.videoSummariesForPediatricPatients[k].videoName,
                                videoSrc:  productData.data.videoSummariesForPediatricPatients[k].videoSrc,
                                id:  productData.data.videoSummariesForPediatricPatients[k].id,
                                videoStatus :  productData.data.videoSummariesForPediatricPatients[k].videoStatus
                            })
                        }
                       
                    }
                    if(videoArray.length > 0){                             
                        self.setState({ selectVideoValue : videoArray[0] })                            
                    }
                   
                    self.setState({
                        productListObj: productData.data,
                        selectedTrialLanguageOptions: trialArray,
                        selectedPediatricLanguageOptions: pediatricArray,
                        selectedVideoOptions : videoArray,
                        innerResultsLoader: { class_name: 'loading', api_message: '', showloader: false }
                    },()=>{
                        if(videoArray.length > 0){                             
                            document.getElementById('videoBox').innerHTML = videoArray[0].videoName  
                            self.setState({
                                videoLoader: { class_name: 'loading', api_message: '', showloader: false 
                            }})                   
                        }
                        
                    })
                }

            } else {
                self.setState({
                    innerResultsLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true }
                })
            }

        } else {
            self.setState({
                innerResultsLoader: { class_name: 'error', api_message: 'Network error', showloader: true }
            })
        }
    }





    onAccordionClick = (text, title) => {
        if (self.state.selectCategoryValue.label === 'Keywords') {
            let keyObj = self.state.keywordsDataArray;
            for (var a = 0; a < keyObj.length; a++) {
                keyObj[a].click = false
            }
            keyObj[text].click = keyObj[text].click === false ? true : false;
            self.setState({ keywordsDataArray: keyObj });

        } else {
            if (self.state.selectSearchValue === null && (title !== undefined || title !== null || title !== "")) {
                window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + self.state.selectCategoryValue.id + ('&title=' + title.split(' ').join(''))))
            }

            let trailObj = self.state.clinicalTrialDataObj;
            for (var i = 0; i < Object.values(trailObj).length; i++) {
                for (var j = 0; j < Object.values(trailObj)[i].length; j++) {
                    Object.values(trailObj)[i][j].click = false;
                }
            }
            for (var k = 0; k < Object.values(trailObj)[text].length; k++) {
                Object.values(trailObj)[text][k].click = (Object.values(trailObj)[text][k].click === false ? true : false)
            }
            self.setState({ clinicalTrialDataObj: trailObj });
        }

    }
    onHideAccordionClick = () => {
        if (self.state.selectCategoryValue.label === 'Keywords') {
            let keyObj = self.state.keywordsDataArray;
            for (var a = 0; a < keyObj.length; a++) {
                keyObj[a].click = false
            }
            self.setState({ keywordsDataArray: keyObj });

        } else {
            if (self.state.selectSearchValue === null) {
                window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + self.state.selectCategoryValue.id))
            }
            let trailObj = self.state.clinicalTrialDataObj;
            for (var i = 0; i < Object.values(trailObj).length; i++) {
                for (var j = 0; j < Object.values(trailObj)[i].length; j++) {
                    Object.values(trailObj)[i][j].click = false;
                }
            }

            self.setState({ clinicalTrialDataObj: trailObj });
        }
    }


    onInnerAccordionClick = (firstIndex, secondIndex) => {
        let keyObj = self.state.keywordsDataArray;
        for (var i = 0; i < keyObj[firstIndex].medical.length; i++) {
            keyObj[firstIndex].medical[i].click = false;

            keyObj[firstIndex].medical[secondIndex].click = true;
        }
        self.setState({ keywordsDataArray: keyObj });
    }
    onHideInnerAccordionClick = (firstIndex) => {
        let keyObj = self.state.keywordsDataArray;
        for (var i = 0; i < keyObj[firstIndex].medical.length; i++) {
            keyObj[firstIndex].medical[i].click = false;
        }
        self.setState({ keywordsDataArray: keyObj });
    }

    onInnerDataClick = (data, sponsor) => {
        let pathName = '';
        if (self.state.selectCategoryValue.label === 'Medical Condition') {
            pathName = `/product?type=clinicalM&medicalConditionId=${data.medicalConditionId}&productId=${data.productId}`
        } else if (self.state.selectCategoryValue.label === 'Product') {
            pathName = `/product?type=clinicalP&medicalConditionId=${data.medicalConditionId}&productId=${data.productId}`
        } else if (self.state.selectCategoryValue.label === 'Keywords') {
            pathName = `/product?type=clinicalK&KeywordId=${self.state.selectSearchValue.id}&sponsor=${sponsor}&title=${data.productGenricName}&medicalConditionId=${data.medicalConditionId}`
        }
        this.props.history.push(`${pathName}`);
        window.location.hash = `#${pathName}`;
    }


    onPDFBtnClick = (text, data) => {
        let url = "";
        if (text === "Technical") {
            url = serverURL + 'trialresult/trialresults/pdf?trialResultId=' + data.trialResultId;
        } else {
            url = serverURL + 'trialresult/journalsummary/pdf?trialResultId=' + data.trialResultId;
        }
        window.open(url, '_blank');
    }

    onContinueBtnClick = (text, data, tab) => {
        if (text) {
            document.body.classList.add('bodyActive');
        } else {
            document.body.classList.remove('bodyActive');
        }
        let url = '';
        if (tab === 'NCT') {
            url = `https://www.clinicaltrials.gov/ct2/show/NCT${data.nctNumber}?term=${data.technicalResultSummary}&rank=1`;
        } else if (tab === 'EudraCT') {
            url = `https://www.clinicaltrialsregister.eu/ctr-search/search?query=${data.eduraCtNumber}`;
        }

        self.setState({ showContinuePop: text, continueLinkText: url });
    }
    onMoveContinueBtnClick = () => {
        document.body.classList.remove('bodyActive');
        self.setState({ showContinuePop: false });
        window.open(self.state.continueLinkText, '_blank');
    }

    onRelatedLinkClick = (link) => {
        window.open(link, '_blank');
    }


    handleTrialLanguageChange = (newValue) => {
        self.setState({ selectTrialLanguageValue: newValue, 
            pediatricLanguageError: false, 
            trialLanguageError: false
         })
    }

    handlePediatricLanguageChange = (newValue) => {
        self.setState({ selectPediatricLanguageValue: newValue, 
            pediatricLanguageError: false, 
            trialLanguageError: false
        })
    }

    handleVideoLanguageChange=(newValue) =>{
        document.getElementById('videoBox').innerHTML = ""  
        self.setState({ 
            selectVideoValue : newValue,             
            videoLoader: { class_name: 'loading', api_message: '', showloader: true },
            pediatricLanguageError: false, 
            trialLanguageError: false
        },()=>{
               let videoData = self.state.productListObj.videoSummariesForPediatricPatients
               for(var i=0;i<videoData.length;i++){
                   if(newValue.languageId === videoData[i].languageId){
                    document.getElementById('videoBox').innerHTML = videoData[i].videoName  
                    setTimeout(() => {
                        self.setState({ videoLoader: { class_name: 'loading', api_message: '', showloader: false } })
                    }, 200);
                    
                   }
               }
            })
    }

    TrialLanguageBtnClick = async () => {
        self.setState({ trialLanguageError: false })
        if (self.state.selectTrialLanguageValue === null) {
            self.setState({ trialLanguageError: true })
        } else {
            let url = serverURL + `patientsummary/patientsummaries?patientSummaryId=${self.state.selectTrialLanguageValue.value}`;
            window.open(url, '_blank');
        }
    }
    PediatricLanguageBtnClick = async () => {
        self.setState({ pediatricLanguageError: false })
        if (self.state.selectPediatricLanguageValue === null) {
            self.setState({ pediatricLanguageError: true })
        } else {
            let url = serverURL + `ppatientsummary/ppatientsummaries?periodicPatientSummaryId=${self.state.selectPediatricLanguageValue.value}`;
            window.open(url, '_blank');
        }
    }



    onStudyNumberAccordionClick = (text, data, title) => {
        if (self.state.selectSearchValue === null && (title !== undefined || title !== null)) {
            window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + self.state.selectCategoryValue.id + ('&title=' + title.split(' ').join(''))))
        }

        let studyObj = self.state.studyItemArray;
        for (var i = 0; i < studyObj.length; i++) {
            studyObj[i].click = false
        }
        studyObj[text].click = studyObj[text].click === false ? true : false;
        self.setState({
            studyItemArray: studyObj,
            selectTrialLanguageValue: null,
            selectPediatricLanguageValue: null,
            selectVideoValue: null,
            selectedTrialLanguageOptions: [],
            selectedPediatricLanguageOptions: [],
            selectedVideoOptions: [],
            trialLanguageError: false,
            pediatricLanguageError: false,
        }, () => {
            self.productListDataCall(data);
        });
    }
    onHideStudyNumberAccordionClick = () => {
        if (self.state.selectSearchValue === null) {
            window.history.replaceState(null, null, ('#/clinicaltrialresults?category=' + self.state.selectCategoryValue.id))
        }
        let studyObj = self.state.studyItemArray;
        for (var i = 0; i < studyObj.length; i++) {
            studyObj[i].click = false
        }
        self.setState({ studyItemArray: studyObj });
    }

    onProvacyBtnClick = () => {
        self.setState({ showContinuePop: false })
        document.body.classList.remove('bodyActive');
        window.open("#/privacypolicy", "_blank");
    }

    render() {
        const {
            relatedLinksPosition,
            googleTranslateRelatedPosition,
            filterFixed,
            filterFixedGoogleApply,
            selectCategoryValue,
            selectedCategoryOptions,
            selectSearchValue,
            selectedSearchOptions,
            showFeedbackPop,
            setTimer,
            feedbackGoogleClick,
            resultsLoader,
            searchValueError,
            searchResultsDisplayedHere,
            clinicalTrialDataObj,
            keywordsDataArray,
            showContinuePop,
            feedbackDivShow,
            selectTrialLanguageValue,
            selectPediatricLanguageValue,
            selectVideoValue,
            selectedTrialLanguageOptions,
            selectedPediatricLanguageOptions,
            selectedVideoOptions,
            pediatricLanguageError,
            trialLanguageError,
            searchLoadingState,
            searchNoDataState,
            productListObj,
            studyItemArray,
            innerResultsLoader,
            videoLoader
        } = this.state
        return (
            <div className="main">
                <Header activeTabName={'clinicaltrialresults'}
                    FeedbackPop={showFeedbackPop}
                    feedbackGoogleClick={feedbackGoogleClick}
                />
                <div id="mainSection">
                    <div id="page">
                        <div className="section first-section">
                            <div className="container">


                                <div className="breadcrumb">
                                    <ol>
                                        <li><Link to="/index">Home</Link></li>
                                        <li><span> › </span> </li>
                                        <li className="active">Clinical Trial Results </li>
                                        <div className="clear"></div>
                                    </ol>
                                </div>

                                <div className="left-section pull-left">
                                    <div className="title-item title_gap">
                                        <div className="title"> Search Clinical Trial Results</div>
                                        <p className="para">To view Novartis clinical trial results, use drop down menu below to select the Medical Condition, Product Code, Study Number or Keywords {'{'}e.g. Generic name{'}'} </p>
                                    </div>
                                    <div className={filterFixed ? "search-form high fixed" : filterFixedGoogleApply ? "search-form high fixed google_fixed" : "search-form high"}>
                                        <div className="item select-item">
                                            <Select
                                                value={selectCategoryValue}
                                                onChange={this.handleCategoryChange}
                                                options={selectedCategoryOptions}
                                                className="select-box"
                                                placeholder={"Select Category"}
                                                isSearchable={false}
                                            />
                                        </div>
                                        <div className="item select-item input-item">
                                            <Select
                                                ref={input => this.input = input}
                                                name="form-field-name"
                                                searchable
                                                autoBlur
                                                clearable={false}
                                                openOnFocus
                                                noOptionsMessage={searchNoDataState ? () => { } : () => null}
                                                onInputChange={this.handleInputChange.bind(this)}
                                                onKeyDown={this.onInputKeyDown}
                                                onChange={this.handleSearchChange}
                                                value={selectSearchValue}
                                                options={selectedSearchOptions || []}
                                                placeholder={selectCategoryValue.label === "Medical Condition" ? "Search by Medical Condition" : selectCategoryValue.label === "Product" ? "Search by Product Code or Generic Drug Name" : selectCategoryValue.label === "Study Number" ? "Search by Study Number" : selectCategoryValue.label === "Keywords" ? "Enter any Keywords" : ""}
                                                isClearable={true}
                                                isLoading={searchLoadingState ? true : false}
                                            />

                                            {searchValueError &&
                                                <div className="minimum-error"> Please enter at least 3 characters  </div>
                                            }
                                        </div>
                                        <div className="item icon-item">
                                            <div className="icon" onClick={() => self.onSearchIconClick()}></div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className={(filterFixed || filterFixedGoogleApply) ? "results_content fixed" : "results_content"}>
                                        <div className="results-div">
                                            {resultsLoader.showloader && Common.submitLoader(resultsLoader)}

                                            {(selectCategoryValue.label === "Medical Condition" || selectCategoryValue.label === "Product") &&
                                                <div className="accordion-box">
                                                    {Object.keys(clinicalTrialDataObj).length > 0 && <div className="accordion-center">
                                                        {selectCategoryValue.label === "Product" && <div className="product_main_title"> Product Code/Generic name </div>}

                                                        {Object.entries(clinicalTrialDataObj).map(([key, subject], index) => (

                                                            <div id={((key === undefined || key === null) ? '' : (key.split(' ').join('')))} className={subject[0].click ? "accordion-item active" : "accordion-item"} key={index}>

                                                                <div className={subject[0].click ? "accordion-head accordion-expand" : "accordion-head"} onClick={subject[0].click === false ? () => this.onAccordionClick(index, key) : () => this.onHideAccordionClick(index)}>
                                                                    <div className="accordion-title">{key}</div>
                                                                </div>
                                                                {subject[0].click && <div className="accordion-body">
                                                                    {selectCategoryValue.label === "Medical Condition" && <div className="product_title"> Product Code/Generic name </div>}
                                                                    <ul>
                                                                        {subject.map((innerData, subjectIndex) => (
                                                                            <li key={'innerData' + subjectIndex}>
                                                                                <p onClick={() => self.onInnerDataClick(innerData)}>{(selectCategoryValue.label === "Medical Condition") ? innerData.productGenricName : innerData.medicalConditionName}</p></li>
                                                                        ))}
                                                                    </ul>
                                                                </div>}
                                                            </div>
                                                        ))}
                                                    </div>}
                                                </div>
                                            }

                                            {(selectCategoryValue.label === "Study Number") && <div id="studyAccordion">

                                                <div className="accordion-box">
                                                    {studyItemArray.length > 0 && <div className="accordion-center">
                                                        <div className="product_main_title">List of Study Numbers </div>
                                                        {studyItemArray.map((studyData, studyIndex) => (
                                                            <div id={(studyData.label === undefined || studyData.label === null) ? '' : (studyData.label.split(' ').join(''))} className={studyData.click ? "accordion-item active" : "accordion-item"} key={"studyData" + studyIndex}>
                                                                <div className="accordion-head accordion-expand" onClick={studyData.click === false ? () => this.onStudyNumberAccordionClick(studyIndex, studyData, studyData.label) : () => this.onHideStudyNumberAccordionClick(studyIndex)}>
                                                                    <div className="accordion-title" eventlabel={studyData.label}>{studyData.label}</div>
                                                                </div>
                                                                {studyData.click && <div className="accordion-body">

                                                                    <div className="study-box product-study-box">
                                                                        {innerResultsLoader.showloader && Common.submitLoader(innerResultsLoader)}
                                                                        {(Object.keys(productListObj).length > 0) && <div className="study-box-inner">
                                                                            <div className="study-item">
                                                                                <p>Sponsor</p>
                                                                                <p>{productListObj.sponsor === null ? '--' : productListObj.sponsor}</p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            <div className="study-item">
                                                                                <p>Product code</p>
                                                                                <p>{productListObj.productName === null ? '--' : productListObj.productName}</p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            <div className="study-item">
                                                                                <p>Generic drug name</p>
                                                                                <p>{productListObj.genricDrugName === null ? '--' : productListObj.genricDrugName}</p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            <div className="study-item">
                                                                                <p>NCT number</p>
                                                                                <p>{productListObj.nctNumber === null ? '--' : <span onClick={() => self.onContinueBtnClick(true, productListObj, 'NCT')}>{productListObj.nctNumber}</span>}</p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            <div className="study-item">
                                                                                <p>EudraCT number</p>
                                                                                <p>{productListObj.eduraCtNumber === null ? '--' : <span onClick={() => self.onContinueBtnClick(true, productListObj, 'EudraCT')}>{productListObj.eduraCtNumber}</span>}</p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            <div className="study-item">
                                                                                <p>Medical condition</p>
                                                                                <p>{productListObj.medicalCondition === null ? '--' : productListObj.medicalCondition}</p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            <div className="study-item">
                                                                                <p>Protocol title</p>
                                                                                <p>{productListObj.protoclTitle === null ? '--' : productListObj.protoclTitle}</p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            <div className="study-item">
                                                                                <p eventlabel="Technical result summary">Technical result summary</p>
                                                                                <p className={productListObj.technicalResultSummary === null ? "" : "pdf"}> {productListObj.technicalResultSummary === null ? '--' : <span onClick={() => self.onPDFBtnClick("Technical", productListObj)}> {productListObj.technicalResultSummary} </span>} </p>
                                                                                <div className="clear"></div>
                                                                            </div>
                                                                            {productListObj.journalSummaryName !== null && <div className="study-item">
                                                                                <p eventlabel="Journal Summary for Patients">Journal Summary for Patients</p>
                                                                                <p className="pdf"> <span onClick={() => self.onPDFBtnClick("Journal", productListObj)}> {productListObj.studyCode} </span> </p>
                                                                                <div className="clear"></div>
                                                                            </div>}

                                                                             {productListObj.trialSummaryForPatents !== null && <div className="study-item">
                                                                            <p eventlabel="Trial Summaries for Patients">Trial Summaries for Patients</p>
                                                                            <div className="study-item-select top" style={{ zIndex: 3 }}>
                                                                                <div className="search-select pull-left" style={{ zIndex: 3 }}>
                                                                                    <Select
                                                                                        value={selectTrialLanguageValue}
                                                                                        onChange={this.handleTrialLanguageChange}
                                                                                        options={selectedTrialLanguageOptions}
                                                                                        className="select-box"
                                                                                        placeholder={"Select Language"}
                                                                                        isClearable={true}
                                                                                    />
                                                                                    {trialLanguageError && <div className="minimum-error" style={{ zIndex: 1 }}> Please select language </div>}
                                                                                </div>
                                                                                <div className="icon pull-right" id="indicationSearchIcon" onClick={() => self.TrialLanguageBtnClick()}></div>
                                                                                <div className="clear"></div>

                                                                            </div>
                                                                            <div className="clear"></div>
                                                                        </div>}

                                                                        {productListObj.pediatricPatientLaySummary !== null && <div className="study-item">
                                                                            <p eventlabel="Pediatric Trial Summaries for Patients">Pediatric Trial Summaries for Patients</p>
                                                                            <div className="study-item-select" style={{ zIndex: 2 }}>
                                                                                <div className="search-select pull-left" style={{ zIndex: 2 }}>
                                                                                    <Select
                                                                                        value={selectPediatricLanguageValue}
                                                                                        onChange={this.handlePediatricLanguageChange}
                                                                                        options={selectedPediatricLanguageOptions}
                                                                                        className="select-box"
                                                                                        placeholder={"Select Language"}
                                                                                        isClearable={true}
                                                                                    />
                                                                                </div>
                                                                                {pediatricLanguageError && <div className="minimum-error"> Please select language </div>}
                                                                                <div className="icon pull-right" id="indicationSearchIcon" onClick={() => self.PediatricLanguageBtnClick()}></div>
                                                                                <div className="clear"></div>

                                                                            </div>
                                                                            
                                                                            <div className="clear"></div>
                                                                        </div>}

                                                                        {productListObj.videoSummariesForPediatricPatients !== null && <div className="study-item">
                                                                            <p eventlabel="Video Summaries for Pediatric Patients"> Video Summaries for Pediatric Patients </p>
                                                                            <div className="study-item-select" style={{ zIndex: 1 }}>
                                                                                <div className="search-select pull-left" style={{ zIndex: 1, width: '100%' }}>
                                                                                    <Select
                                                                                        value={selectVideoValue}
                                                                                        onChange={this.handleVideoLanguageChange}
                                                                                        options={selectedVideoOptions}
                                                                                        className="select-box"
                                                                                        placeholder={"Select Language"}
                                                                                        isClearable={false}
                                                                                    />
                                                                                </div>


                                                                                <div className="clear"></div>
                                                                               
                                                                            </div>
                                                                            <div className="clear"></div>
                                                                            <div className="video_box">
                                                                                {videoLoader.showloader && Common.submitLoader(videoLoader)}
                                                                                    <div id="videoBox"></div>
                                                                        </div>
                                                                        </div>}


                                                                        </div>}
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        ))}

                                                    </div>}
                                                </div>

                                            </div>}

                                            {selectCategoryValue.label === "Keywords" && <div className="accordion-box" id="keyAccordion">
                                                {Object.keys(keywordsDataArray).length > 0 && <div className="accordion-center">
                                                    {Object.entries(keywordsDataArray).map(([sponsorKey, sponsorValue], sponsorIndex) => (
                                                        <div id={(sponsorValue.sponsor === undefined || sponsorValue.sponsor === null) ? "" : (sponsorValue.sponsor.split(' ').join(''))} className={sponsorValue.click ? "accordion-item active" : "accordion-item"} key={'sponsorItem' + sponsorIndex}>
                                                            <div className={sponsorValue.click ? "accordion-head accordion-expand" : "accordion-head"} onClick={sponsorValue.click === false ? () => this.onAccordionClick(sponsorIndex, sponsorValue.sponsor) : () => this.onHideAccordionClick(sponsorIndex)}>
                                                                <div className="accordion-title">{sponsorValue.sponsor}</div>
                                                            </div>

                                                            {sponsorValue.click && <div className="accordion-body">
                                                                {sponsorValue.medical.map((medicalItem, medicalIndex) => (
                                                                    <div className={medicalItem.click ? "inner-item active" : "inner-item"} key={"medicalItem" + medicalIndex}>
                                                                        <div className="inner-head" onClick={medicalItem.click === false ? () => this.onInnerAccordionClick(sponsorIndex, medicalIndex) : () => this.onHideInnerAccordionClick(sponsorIndex, medicalIndex)}>
                                                                            <div className="inner-title">{medicalItem.medicalName}</div>
                                                                        </div>
                                                                        {medicalItem.click && <div className="inner-body">
                                                                            <ul>
                                                                                {medicalItem.medicalValue.map((innerData, innerIndex) => (
                                                                                    <li key={"innerData" + innerIndex}>
                                                                                        <p onClick={() => self.onInnerDataClick(innerData, sponsorValue.sponsor)} >{innerData.medicalConditionName}</p></li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>}
                                                                    </div>
                                                                ))}
                                                            </div>}
                                                        </div>
                                                    ))}
                                                </div>}
                                            </div>}

                                            {searchResultsDisplayedHere && <div className="noresultbox" id="noresultbox">
                                                <div className="noresultbox-inner">
                                                    <div className="icon"></div>
                                                    <p>Search results will be displayed here...</p>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </div>


                                <div className="right-section pull-right">
                                    {feedbackDivShow && <div className="feedBack-box">
                                        <div className="center">
                                            <div className="feedBack-icon"> </div>
                                            <div className="feedBack-box-right">
                                                <p> Your Feedback is important to us. </p>

                                                <div className="read-more white animate_btn" id="feedBackBtn" onClick={() => self.feedbackBtnClick()}>
                                                    <p>Feedback</p>
                                                </div>

                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>}

                                    <div className={googleTranslateRelatedPosition ? "related-sec translate-fixed" : relatedLinksPosition === "related" ? "related-sec" : relatedLinksPosition === "fixed" ? "related-sec fixed" : "related-sec absolute"}>
                                        <div className="line full"></div>
                                        <h2 className="related-title">Related Links</h2>
                                        <div className="related-content">
                                            <ul>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartisclinicaltrials.com/TrialConnectWeb/whatisclinicaltrials.nov")}>
                                                        What are Clinical Trials? <span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartisclinicaltrials.com/TrialConnectWeb/benefits.nov")}>
                                                        Clinical Trial Benefits and Risks <span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartis.com/our-focus/patients-caregivers")}>
                                                        Our Focus/Patient and Caregivers <span></span> </p></li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://clinicaltrials.gov/")}>
                                                        ClinicalTrials.gov <span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.clinicaltrialsregister.eu/")}>EU Clinical
                                            Trials Register<span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartis.com/sites/www.novartis.com/files/clinical-trial-data-transparency.pdf")}>
                                                        Novartis Position on Clinical Trials Transparency<span></span> </p>
                                                </li>
                                                <li>
                                                    <p onClick={() => self.onRelatedLinkClick("https://www.novartis.com/sites/www.novartis.com/files/leaders-in-clinical-trial-data-transparency.pdf")}>Leaders
                                            in Clinical Trial Transparency  <span></span> </p>
                                                </li>
                                                <li>
                                                    <Link to="/NovartisProductLibrary" target="_blank">Novartis Product Library <span></span> </Link>
                                                </li>

                                                <div className="clear"></div>
                                            </ul>

                                        </div>
                                    </div>

                                </div>

                                <div className="clear"></div>
                            </div>
                        </div>



                    </div>
                </div>

                <div className="breadcrumb outside" id="breadcrumb">
                    <div className="container">
                        <ol>
                            <li><Link to="/index">Home</Link></li>
                            <li><span> › </span></li>
                            <li className="active">Clinical Trial Results </li>
                            <div className="clear"></div>
                        </ol>
                    </div>
                </div>

                {showFeedbackPop && <Feedbackpop
                    setTimer={setTimer}
                    titleText="Clinical Trial Results"
                    activeTitle="clinicaltrialresults"
                    FeedbackClick={self.FeedbackClick}
                    onCancelFeedback={self.onCancelFeedback}
                    onSuccessFeedback={self.onSuccessFeedback}
                />}

                {showContinuePop && <div className="popup" id="homePopup">
                    <div className="popup-overlay"></div>
                    <div className="popup-center">
                        <div className="popup-body">

                            <div className="popup-close pull-right" onClick={() => self.onContinueBtnClick(false)}></div>

                            <div className="popup-inner">
                                <div className="popup-logo"><img src={novartislogo} alt={""} /> </div>
                                <p className="popup-para">You are now leaving the novctrd.com. This link will take you to a website to which our <span onClick={() => self.onProvacyBtnClick()}> Privacy policy </span> does not apply. You are solely responsible for your interactions with that website.
                        </p>

                                <div className="popup-btn-sec">
                                    <div className="read-more right-gap pull-left" onClick={() => self.onMoveContinueBtnClick(false)}>Continue
                                    </div>
                                    <div className="read-more pull-left hide-pop" onClick={() => self.onContinueBtnClick(false)}>Cancel</div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}


            </div>
        )
    }
}

export default withRouter(Clinicaltrial);