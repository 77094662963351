import React from "react";
import { withRouter } from 'react-router-dom'
import * as Commonapirequest from '../Commonapirequest/Commonapirequest';
import * as Common from '../Common/Common';
let self;
let StarNumberClickActive = false;
class Feedbackpop extends React.Component {
    constructor(props) {
        super(props);
        self = this;
        this.state = {
            activeTitle: null,
            feedbackGoogleDiv: false,
            questionNamesArray: [],
            feedbackLoader: { class_name: 'loading', api_message: '', showloader: true },
            feedbackTitleObj: {},
            csrToken: null,
            titleTabActiveState: 'Clinical Trial Results',
            setTimer: false,
            feedbackSubmitActive: false,
        }
    }
    componentDidMount() {
        StarNumberClickActive = false;
        self.setState({
            activeTitle: this.props.activeTitle
        }, () => {
            self.onFeedbackTitleLoad();
            self.onQuestionDataLoad(this.props.titleText);
        })
    }

    onFeedbackTitleLoad = async () => {
        let passingObj = {
            url: 'usersurvey/feedbacktitle?titleType=FEED_BACK',
            data: null,
            requestMethod: 'get',
        }
        let feedbackTitle = await Commonapirequest.makeApiRequest(passingObj);

        if (feedbackTitle) {
            if (feedbackTitle.status === 200) {
                self.setState({ feedbackTitleObj: feedbackTitle.data })
            }
        }
    }

    onQuestionDataLoad = async (title) => {
        self.setState({
            feedbackLoader: { class_name: 'loading', api_message: '', showloader: true },
            questionNamesArray: []
        })
        let passingObj = {
            url: `usersurvey/feedbackquestions?feedBackType=${title === 'Clinical Trial Results' ? 'CLINICAL_TRIAL_RESULT' : 'TRIAL_SUMMARIES_FOR_PATIENTS'}&k=${Commonapirequest.timeStamp()}`,
            data: null,
            requestMethod: 'get',
        }
        let questions = await Commonapirequest.makeApiRequest(passingObj);

        if (questions) {
            self.setState({ csrToken: questions.headers['x-csrf-token'] });
            if (questions.status === 200) {
                if (questions.data.length > 0) {
                    for (var i = 0; i < questions.data.length; i++) {
                        if (questions.data[i].responseType === 'star') {
                            questions.data[i].click = 0;
                        } else if (questions.data[i].responseType === 'number') {
                            questions.data[i].click = 0;
                        } else if (questions.data[i].responseType === 'yesorno') {
                            questions.data[i].click = 'No';
                            questions.data[i].inner = {
                                id: questions.data[i].id,
                                question: "",
                                responseType: "yesorno",
                                click: 0
                            }
                        }
                    }
                    self.setState({
                        questionNamesArray: questions.data,
                        feedbackLoader: { class_name: 'loading', api_message: '', showloader: false }
                    })
                } else {
                    self.setState({ feedbackLoader: { class_name: 'nodata', api_message: 'No Data Available', showloader: true } })
                }

            } else {
                self.setState({ feedbackLoader: { class_name: 'error', api_message: 'Something Went wrong', showloader: true } })
            }
        } else {
            self.setState({ feedbackLoader: { class_name: 'error', api_message: 'Network error', showloader: true } })
        }
    }

    yesNoBtnClick = (text, index)=>{    
        let a = self.state.questionNamesArray;
        if(a[index].click !== text){   
            a[index].click = text;

            if(text === "No"){
                a[index].inner.click = 0       
                if(!StarNumberClickActive){         
                    self.setState({ feedbackSubmitActive:  false })
                    for(var i=0;i<a.length;i++){                        
                        if(a[i].inner  !== undefined){
                            if(a[i].inner.click !== 0){
                                setTimeout(() => {                                    
                                    self.setState({ feedbackSubmitActive:  true }) 
                                }, 1);
                            }
                        }
                    } 
                }
            }

            self.setState({ questionNamesArray: a });
        }
    }

    onClickFeedbackAction = (text, index) => {
        StarNumberClickActive = true;
        let a = self.state.questionNamesArray;
        a[index].click = text;
        self.setState({
            questionNamesArray: a,
            feedbackSubmitActive:  true
        });
    }
    onYesNoRadioBtnClick = (text, index) => {
        let a = self.state.questionNamesArray;
        a[index].inner.click = text;
        self.setState({
            questionNamesArray: a,
            feedbackSubmitActive: true
        });
    }

    onFeedbackGoogleClick = () => {
        self.setState({ feedbackGoogleDiv: self.state.feedbackGoogleDiv === true ? false : true },
            () => { self.props.FeedbackClick(self.state.feedbackGoogleDiv) });
        if (self.state.feedbackGoogleDiv) {
            document.body.classList.remove('google');
        } else {
            document.body.classList.add('google');
        }

        document.getElementById("google_translate_element").addEventListener('change', function (e) {
            self.setState({ feedbackGoogleDiv: false })
            document.body.classList.remove('google');
        })

    }

    onFeedbackSubmit = async () => {
        self.setState({ feedbackLoader: { class_name: 'loading', api_message: '', showloader: true } })
        let designationId = localStorage.getItem("designationId")
        let ufDtos = [];
        for (var i = 0; i < self.state.questionNamesArray.length; i++) {
            let obj = {};
            if (self.state.questionNamesArray[i].responseType === 'star' || self.state.questionNamesArray[i].responseType === 'number') {
                obj = {
                    id: self.state.questionNamesArray[i].id,
                    response: self.state.questionNamesArray[i].click,
                    responseProvided: (self.state.questionNamesArray[i].click === 0 ? 0 : 1)
                }
            } else {
                obj = {
                    id: self.state.questionNamesArray[i].id,
                    response: self.state.questionNamesArray[i].inner.click,
                    responseProvided: (self.state.questionNamesArray[i].click === 'No' ? 0 : (self.state.questionNamesArray[i].inner.click === 0 ? 0 : 1))
                }
            }
            ufDtos.push(obj)
        }
        ufDtos = ufDtos.filter(function (obj) {
            return obj.responseProvided !== 0;
        });

        let passingObj = {
            url: `usersurvey/${designationId}/update?k=${Commonapirequest.timeStamp()}`,
            data: ufDtos,
            requestMethod: 'post',
            token: self.state.csrToken
        }
        let feedbackSend = await Commonapirequest.makeApiRequest(passingObj);
        
        if (feedbackSend) {
            if (feedbackSend.status === 200) {
                self.setState({ feedbackLoader: { class_name: 'success', api_message: feedbackSend.data, showloader: true } })
                localStorage.setItem((self.state.activeTitle === 'clinicaltrialresults') ? 'ClinicalFeedback' : 'SummaryFeedback', true)
                setTimeout(() => {
                    self.setState({
                        questionNamesArray: [],
                        feedbackLoader: { class_name: 'loading', api_message: '', showloader: false }
                    }, () => {
                        self.props.onCancelFeedback();
                        self.props.onSuccessFeedback();
                    })
                }, 3000);
            } else {
                self.setState({ feedbackLoader: { class_name: 'error', api_message: 'Something Went wrong', showloader: true } })
                setTimeout(() => {
                    self.setState({ feedbackLoader: { class_name: 'loading', api_message: '', showloader: false } })
                }, 3000);
            }
        } else {
            self.setState({ feedbackLoader: { class_name: 'error', api_message: 'Network error', showloader: true } })
            setTimeout(() => {
                self.setState({ feedbackLoader: { class_name: 'loading', api_message: '', showloader: false } })
            }, 3000);
        }
    }

    render() {
        const {
            questionNamesArray,
            feedbackLoader,
            feedbackTitleObj,
            feedbackSubmitActive,
        } = this.state
        return (
            <div className="feedback-pop">
                <div className="google-translate-btn" id="feedbackGoogle" title="Google Translate" onClick={() => self.onFeedbackGoogleClick()}> Google Translate
                    <span></span>
                </div>
                <div className="center" style={{ minHeight: '300px' }}>


                    <div className="feedback-title">
                        <h1>{(feedbackTitleObj.name === undefined || feedbackTitleObj.name === null || feedbackTitleObj.name === '') ? 'Feedback' : feedbackTitleObj.name}</h1>
                        {/* <div className="close" onClick={() => self.props.onCancelFeedback()}></div> */}
                        <div className="clear"></div>
                    </div>
                    <div className="feedback-cont">
                        {feedbackLoader.showloader && Common.submitLoader(feedbackLoader)}
                        <div className="para">{(feedbackTitleObj.title === undefined || feedbackTitleObj.title === null || feedbackTitleObj.title === '') ? 'Your opinion is important to us. Please take a few seconds to answer few questions. The survey is anonymous and the answers are treated confidentially.' : feedbackTitleObj.title}</div>

                        <div className="form">

                            {questionNamesArray.map((item, index) => (
                                <div className="form-item" key={index}>
                                    <h1>{item.question}</h1>

                                    {item.responseType === 'star' && <div className="cont star">
                                        <div className="stars">
                                            <div className={(item.click === 1 || item.click === 2 || item.click === 3 || item.click === 4 || item.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onClickFeedbackAction(1, index)}><i className={(item.click === 1 || item.click === 2 || item.click === 3 || item.click === 4 || item.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                            <div className={(item.click === 2 || item.click === 3 || item.click === 4 || item.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onClickFeedbackAction(2, index)}><i className={(item.click === 2 || item.click === 3 || item.click === 4 || item.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                            <div className={(item.click === 3 || item.click === 4 || item.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onClickFeedbackAction(3, index)}><i className={(item.click === 3 || item.click === 4 || item.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                            <div className={(item.click === 4 || item.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onClickFeedbackAction(4, index)}><i className={(item.click === 4 || item.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                            <div className={item.click === 5 ? "star-icon active" : "star-icon"} onClick={() => self.onClickFeedbackAction(5, index)}><i className={item.click === 5 ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>}
                                    {item.responseType === 'number' && <div className="form-item">

                                        <div className="cont">
                                            <div className="rating">
                                                <div className={(item.click >= 1 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating1" onClick={() => self.onClickFeedbackAction(1, index)}>
                                                    <label>1</label>
                                                </div>
                                                <div className={(item.click >= 2 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating2" onClick={() => self.onClickFeedbackAction(2, index)}>
                                                    <label>2</label>
                                                </div>
                                                <div className={(item.click >= 3 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating3" onClick={() => self.onClickFeedbackAction(3, index)}>
                                                    <label>3</label>
                                                </div>
                                                <div className={(item.click >= 4 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating4" onClick={() => self.onClickFeedbackAction(4, index)}>
                                                    <label>4</label>
                                                </div>
                                                <div className={(item.click >= 5 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating5" onClick={() => self.onClickFeedbackAction(5, index)}>
                                                    <label>5</label>
                                                </div>
                                                <div className={(item.click >= 6 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating6" onClick={() => self.onClickFeedbackAction(6, index)}>
                                                    <label>6</label>
                                                </div>
                                                <div className={(item.click >= 7 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating7" onClick={() => self.onClickFeedbackAction(7, index)}>
                                                    <label>7</label>
                                                </div>
                                                <div className={(item.click >= 8 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating8" onClick={() => self.onClickFeedbackAction(8, index)}>
                                                    <label>8</label>
                                                </div>
                                                <div className={(item.click >= 9 || item.click === 10) ? "rating-item active" : "rating-item"} id="rating9" onClick={() => self.onClickFeedbackAction(9, index)}>
                                                    <label>9</label>
                                                </div>
                                                <div className={(item.click === 10) ? "rating-item active" : "rating-item"} id="rating10" onClick={() => self.onClickFeedbackAction(10, index)}>
                                                    <label>10</label>
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                            <div className="like-text">
                                                <h5 className="pull-left">Not satisfied</h5>
                                                <h5 className="pull-right">Really satisfied</h5>
                                                <div className="clear"></div>
                                            </div>
                                        </div>
                                    </div>}

                                    {item.responseType === 'yesorno' && <div className="yesornoDiv">
                                        <div className="cont">
                                            <div className="radio-box">
                                                <div className={item.click === 'Yes' ? "radio-item active" : "radio-item"} onClick={() => self.yesNoBtnClick("Yes", index)}>Yes
                                <span className="checkmark"></span>
                                                </div>
                                                <div className={item.click === 'No' ? "radio-item active" : "radio-item"} onClick={() => self.yesNoBtnClick("No", index)}>No
                                <span className="checkmark"></span>
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                        </div>

                                        {item.click === 'Yes' && <div className="inner-form-item">

                                            <div className="cont star">
                                                <div className="stars">
                                                    <div className={(item.inner.click === 1 || item.inner.click === 2 || item.inner.click === 3 || item.inner.click === 4 || item.inner.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onYesNoRadioBtnClick(1, index)}><i className={(item.inner.click === 1 || item.inner.click === 2 || item.inner.click === 3 || item.inner.click === 4 || item.inner.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                                    <div className={(item.inner.click === 2 || item.inner.click === 3 || item.inner.click === 4 || item.inner.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onYesNoRadioBtnClick(2, index)}><i className={(item.inner.click === 2 || item.inner.click === 3 || item.inner.click === 4 || item.inner.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                                    <div className={(item.inner.click === 3 || item.inner.click === 4 || item.inner.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onYesNoRadioBtnClick(3, index)}><i className={(item.inner.click === 3 || item.inner.click === 4 || item.inner.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                                    <div className={(item.inner.click === 4 || item.inner.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onYesNoRadioBtnClick(4, index)}><i className={(item.inner.click === 4 || item.inner.click === 5) ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                                    <div className={(item.inner.click === 5) ? "star-icon active" : "star-icon"} onClick={() => self.onYesNoRadioBtnClick(5, index)}><i className={item.inner.click === 5 ? "fa fa-star" : "fa fa-star-o"} aria-hidden="true"></i></div>
                                                    <div className="clear"></div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>}


                                </div>

                            ))}


                        </div>

                        <div className="buttons-section">
                            <div className={feedbackSubmitActive ? "read-more submit-btn" : "read-more submit-btn disabled"} id="endDone" onClick={feedbackSubmitActive ? () => self.onFeedbackSubmit() : () => { }}>Submit</div>
                            <div className="read-more cancel-btn" id="cancelFeedback" onClick={() => self.props.onCancelFeedback()}>Later</div>
                            <div className="clear"></div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Feedbackpop);
