import React from "react";
import { withRouter } from 'react-router-dom'
import Header from '../Header/Header';

class Termsofuse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        // if (localStorage.getItem("survey") === null) {
        //     this.props.history.push(`/index`);
        //          return false;
        // }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }


    render() {
        return (
            <div className="main">
                <Header activeTabName={'termsofuse'} />
                <div id="mainSection">
                    <div id="page">
                        <div className="section text-area">
                            <div className="container">

                                <h1>Terms of use</h1>

                                <h3>Updated April 26, 2010</h3>

                                <h2>1. Acceptance</h2>
                                <p className="para">Your access to and use of this Site is subject to the following terms and conditions and all applicable laws. By accessing and browsing this Site, you accept, without limitation or qualification, these Terms and Conditions and
                                    acknowledge that any other agreements regarding the use of this Site between you and Novartis AG are superseded and of no force or effect.
                        </p>

                                <h2>2. Medical information/conditions</h2>
                                <p className="para">Product information on this web site is provided by Novartis AG and is intended for general information purposes only. Many pharmaceutical and medical device products listed are available upon prescription from a medical doctor
                            or qualified medical professional only, and not all such products may be available in all countries. <span> The product information is notintended to provide complete medical information. SHOULD YOU HAVE A MEDICAL CONDITION, PERSONALIZED MEDICAL DIAGNOSIS OR PATIENT-SPECIFIC TREATMENT ADVICE.</span> You should always obtain complete medical information about your prescription medicines or medical devices (including their beneficial medical uses and possible adverse effects) by
                                                                discussing the appropriate use of any medicine(s) or medical devices directly with your prescribing physician or, where appropriate, other medical advisor. Medical professionals may obtain complete medical information from
                                                                the product's information leaflet. Information on these products may vary by country. Patients, physicians and other medical professionals should check with local medical resources and regulatory authorities for information
                                                                appropriate to their country. In addition, current regulations in many countries limit (or even prohibit in some instances) the ability of Novartis AG to provide information and/or to respond directly to patient questions regarding
                            its prescription products. Novartis AG, however, will respond to inquiries from and provide information to your qualified health care professional in accordance with local regulations.</p>
                                <h2>3. Use of information</h2>
                                <p className="para">You may freely browse the Site, but you may only access, download or use information from this Site, including any text, images, audio, and video (the "Information") for your own non-commercial use. You may not distribute, modify,
                                    transmit, reuse, repost, or use the Information for commercial purposes, without written permission of Novartis AG. You must retain and reproduce each and every copyright notice or other proprietary rights notice contained
                                    in any Information you download. You should assume that everything you see or read on this Site is copyrighted unless otherwise noted and may not be used except as provided in these Terms and Conditions or in the text on the
                                    Site without the written permission of Novartis AG. Except as otherwise permitted in this paragraph, Novartis AG neither warrants nor represents that your use of materials displayed on the Site will not infringe rights of third
                                    parties not owned by or affiliated with Novartis AG. With the exception of the foregoing limited authorization, no license to or right in the Information, or any copyright of Novartis AG or of any other party is granted or
                            conferred to you.</p>

                                <h2>4. Trademarks/proprietary rights</h2>
                                <p className="para">You should assume that all product names appearing on this Site, whether or not appearing in large print, italics or with the trademark symbol are trademarks of Novartis. This Site may also contain or reference patents, proprietary
                                    information, technologies, products, processes or other proprietary rights of Novartis AG and/or other parties. No license to or right in any such trademarks, patents, trade secrets, technologies, products, processes and other
                            proprietary rights of Novartis AG and/or other parties is granted to or conferred upon you. All product names published in italics on this Site are trademarks owned by or licensed to the Novartis Group.</p>

                                <h2>5. Disclaimer of warranties</h2>
                                <p className="para">While Novartis AG uses reasonable efforts to ensure that the Information is accurate and up to date, the Information may contain inaccuracies or typographical errors. Novartis AG reserves the right to make changes, corrections
                                    and/or improvements to the Information, and to the products and programs described in such Information, at any time without notice. Novartis AG makes no warranties or representations as to the accuracy of any of the Information.
                            Novartis AG assumes no liability or responsibility for any errors or omissions in the content of the Site. <span> ALL INFORMATION IS PROVIDED "AS IS." NOVARTIS PROVIDES NO
                                                                                        WARRANTIES ABOUT THE COMPLETENESS OR ACCURACY OF THE INFORMATION ON THIS SITE OR ITS
                                                                                        POSSIBLE USES. CONSEQUENTLY, THE INFORMATION SHOULD BE CAREFULLY EVALUATED BY SITE
                                                                                        VISITORS.
                                                                                        NEITHER NOVARTIS AG, NOR ANY OTHER NOVARTIS GROUP COMPANY, NOR ANY OTHER PARTY INVOLVED
                                                                                        IN
                                                                                        CREATING, PRODUCING OR DELIVERING THIS SITE TO YOU SHALL BE LIABLE FOR ANY DIRECT,
                                                                                        INCIDENTAL, CONSEQUENTIAL, INDIRECT OR PUNITIVE DAMAGES ARISING OUT OF ACCESS TO, USE OF
                                                                                        OR
                                                                                        INABILITY TO USE THIS SITE, OR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THE
								SITE.</span> Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. Novartis AG also assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect,
                            your computer equipment or other property on account of your access to, or use of the Information. Novartis AG reserves the right to discontinue this Site at any time without notice and without liability.</p>

                                <h2>6. Information you supply to us</h2>
                                <p className="para">Except for information covered by our Privacy Policy, any communication or material you transmit to the Site by electronic mail or otherwise, including any data, questions, comments, suggestions or the like is, and will be treated
                                    as, non-confidential and nonproprietary. Anything you transmit or post becomes the property of Novartis AG or its affiliates and may be used for any purpose, including, but not limited to, reproduction, disclosure, transmission,
                                    publication, broadcast and posting. Furthermore, Novartis AG is free to use, without compensation to you, any ideas, concepts, know-how, or techniques contained in any communication you send to the Site for any purpose whatsoever
                                    including, but not limited to, developing, manufacturing and marketing products using such information.
                        </p>

                                <h2>7. Worldwide products</h2>
                                <p className="para">This Site may contain information on worldwide products and services, not all of which are available in every location. A reference to a product or service on this Site does not imply that such product or service is or will be
                                    available in your location. The products referred to on this Site may be subject to different regulatory requirements depending on the country of use. Consequently, visitors may be notified that certain sections of this Site
                                    are intended only for certain kinds of expert users or only for audiences in certain countries. You should not construe anything on this Site as a promotion or advertisement for any product or for the use of any product that
                            is not authorized by the laws and regulations of your country of residence.</p>
                                <h2>8. Disclaimer</h2>
                                <p className="para">Nothing on this Site constitutes an invitation or offer to invest or deal in the securities or American Depositary Receipts of Novartis. In particular, actual results and developments may be materially different from any forecast,
                            opinion or expectation expressed on this Site and the past performance of the price of securities must not be relied on as a guide to their future performance.</p>

                                <h2>9. Links to this site</h2>
                                <p className="para">Novartis AG has not reviewed any or all of the third-party websites which contain links to this Site and is not responsible for the content of any such off-site pages or any other sites linked to the Site. If you wish to link your
                                    website to this Site, you may only link to the home page. You may not link to any other pages within this Site without the prior written consent of Novartis AG. Quotation or use of one or more portions of this Site in the site
                            of any third parties without written consent is likewise prohibited.</p>
                                <h2>10. Links to other sites</h2>
                                <p className="para">Links to third-party sites may be provided for the interest or convenience of visitors to this Site. We will endeavor to inform you when you are leaving this Site that the terms of use and privacy policy of the third-party site
                                    may be different. However, Novartis AG accepts no liability for links from us to others, and in particular we are not responsible for the accuracy or legality of the content thereof. We accept no liability deriving from a breach
                            or omission in the privacy policies of third parties.</p>
                                <h2>11. Postings to this site</h2>
                                <p className="para">Although Novartis AG may from time to time monitor or review discussions, chats, postings, transmissions, message boards, and the like on the Site, Novartis AG is under no obligation to do so and assumes no responsibility or liability
                                    arising from the content of any such locations nor for any error, defamation, libel, slander, omission, falsehood, promotional materials, obscenity, pornography, profanity, danger, privacy disclosure or inaccuracy contained
                                    in any information within such locations on the Site. You are prohibited from posting or transmitting any unlawful, promotional, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane
                                    material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law. Novartis AG will fully cooperate with any law enforcement
                            authorities or court order requesting or directing Novartis AG to disclose the identity of anyone posting any such information or materials.</p>
                                <h2>12. Consequences</h2>
                                <p className="para">If we become aware that you have violated any of the terms and conditions contained in this Legal Statement, we may immediately take corrective action, including preventing the user from using the services offered by Novartis AG
                                    and removing any information, data and content put on the Site by the user, at any moment and without notice. If we have been injured by your violation we may, in our sole discretion, seek to recover damages from you.
                        </p>
                                <h2>13. Revisions</h2>
                                <p className="para">Novartis AG may at any time revise these Terms and Conditions by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the then current Terms and Conditions to which
                            you are bound.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Termsofuse);