import axios from 'axios';

export const makeApiRequest = async (object) => {
    // below is for URL
    let endPointUrl = process.env.REACT_APP_API_ENDPOINT + object.url;
    // below is for POST obj
    let Obj = (object.data === null ? "" : object.data)
    // below is for Method type
    let requestMethod = object.requestMethod

    let checkType = object.checkType !== undefined ? object.checkType : null

    let resolved = {
        data: null,
        error: null
    };
    await axios({
        url: (checkType !== null ? object.url : endPointUrl),
        method: requestMethod,
        data: Obj,
        headers: (checkType !== null ? {} : {
            'Access-Control-Allow-Origin': '*'
        }, (object.token === undefined || object.token === null) ? {} : { 'X-CSRF-TOKEN': object.token })

    }).then((response) => {
        resolved = response
    }).catch((error) => {
        resolved = error.response
    })
    return resolved;
}

export const timeStamp = () => {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);
    return timestamp;
}

export const getServerUrl = () => {
    let externalUrl = process.env.REACT_APP_API_ENDPOINT;
    return externalUrl;
};