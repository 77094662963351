import React from "react";
import { withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';
import ReactGA from 'react-ga';
import Header from '../Header/Header';
import novartislogo from '../../assets/novartis-logo.svg';
import Clinical_Study_Transparency from './../../assets/images/Clinical_Study_Transparency.jpg';
import Clinical_Trial_Transparency from './../../assets/images/Clinical_Trial_Transparency.jpg';

import './Home.scss';

let self;

class Home extends React.Component {
    constructor(props) {
        super(props);
        self = this;
        this.state = {
            showVideoPop: false,
            showContinuePop: false,
            continueLinkText: "",
        }
    }
    componentDidMount() {
        self.initializeReactGA();

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    initializeReactGA = () => {
        let reactGaLink = process.env.REACT_APP_INITIALIZEGA;
        ReactGA.initialize(reactGaLink);
        ReactGA.pageview('/Home');
    }


    onVideoPopClick = (text) => {
        self.setState({ showVideoPop: text });
    }
    onOtherListBtnClick = (link) => {
        window.open(link, '_blank');
    }
    onContinueBtnClick = (text, link) => {
        if (text) {
            document.body.classList.add('bodyActive');
        } else {
            document.body.classList.remove('bodyActive');
        }
        self.setState({ showContinuePop: text, continueLinkText: (link === undefined ? "" : link) });
    }
    onMoveContinueBtnClick = () => {
        document.body.classList.remove('bodyActive');
        self.setState({ showContinuePop: false });
        window.open(self.state.continueLinkText, '_blank');
    }

    onProvacyBtnClick = () => {
        self.setState({ showContinuePop: false })
        document.body.classList.remove('bodyActive');
        window.open("#/privacypolicy", "_blank");
    }

    render() {
        const { showVideoPop, showContinuePop } = this.state

        return (
            <div className="main">
                <Header activeTabName={'home'} />
                <div id="mainSection">
                    <div id="page">
                        <div className="banner">
                            <div className="banner-inner">  </div>
                            <div className="banner-patch patch-scroll">
                                <Scrollbars
                                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" />}
                                    renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                                >
                                    <div className="banner-patch-center">
                                        <div className="banner-title">Clinical Trial Results and Trial Summaries for Patients</div>

                                        <div className="text"> Novartis brings innovative medicines to market to enhance health outcomes for patients and offer solutions to the healthcare providers who treat them.</div>
                                        <div className="text">A clinical trial is a research experiment to find out how people respond to new medical treatments. Novartis has long been dedicated to informing the public about the results of its interventional trials for innovative products.</div>
                                        <div className="text">A trial summary for patients is a trial result written in easier to understand language than the corresponding technical result summary. Novartis provides trial summaries for patients from Phase 1 through 4 interventional trials for innovative products. The trial summary for patients is provided in English and the local languages of the trial patients. </div>
                                        <div className="text">Search for clinical trial results or trial summaries for patients from the above menu bar. </div>
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                        <div className="device-banner-patch">
                            <div className="banner-patch">
                                <div className="banner-title"> Clinical Trial Results and Trial Summaries for Patients </div>

                                <div className="text"> Novartis brings innovative medicines to market to enhance health outcomes for patients and offer solutions to the healthcare providers who treat them.</div>
                                <div className="text"> A clinical trial is a research experiment to find out how people respond to new medical treatments. Novartis has long been dedicated to informing the public about the results of its interventional trials for innovative products.</div>
                                <div className="text"> A trial summary for patients is a trial result written in easier to understand language than the corresponding technical result summary. Novartis provides trial summaries for patients from Phase 1 through 4 interventional trials for innovative products. The trial summary for patients is provided in English and the local languages of the trial patients. </div>
                                <div className="text"> Search for clinical trial results or trial summaries for patients from the above menu bar. </div>
                            </div>
                        </div>

                        <div className="section">
                            <div className="container">
                                <p className="para">Novartis launched the results website in 2005 becoming one of the first companies to provide  results from Phase 2b-4 interventional trials to the public. The Novartis position on clinical study transparency evolved over time to include
                            public disclosure of results from Phase 1- 2a interventional patient trials and Phase 1- 4 trial summaries for patients.</p>

                            </div>
                        </div>
                        <div className="line"></div>
                        <div className="section">
                            <div className="container">
                                <div className="img-text-box">
                                    <div className="img-text-item">
                                        <div className="img-text-left pull-left">
                                            <img src={Clinical_Study_Transparency} alt={""} />
                                        </div>
                                        <div className="img-text-right pull-right">
                                            <div className="title">Novartis Position on Clinical Study Transparency</div>
                                            <p className="para">Novartis' mission is to discover new ways to improve and extend people's lives. Using science-based innovation, Novartis delivers better outcomes for patients and addresses the evolving healthcare needs of society.
                                </p>

                                            <div className="read-more external" onClick={() => self.onOtherListBtnClick("https://www.novartis.com/sites/www.novartis.com/files/clinical-trial-data-transparency.pdf")}>
                                                Click here to learn more  <span></span>
                                            </div>

                                        </div>
                                        <div className="clear"></div>
                                    </div>

                                    <div className="img-text-item">
                                        <div className="img-text-left pull-right hide device-show">
                                            <img src={Clinical_Trial_Transparency} alt={""} />
                                        </div>

                                        <div className="img-text-right pull-left">
                                            <div className="title">Leaders in Clinical Trial Transparency</div>
                                            <p className="para">For over 20 years, Novartis has supported data transparency and was one of the first to commit to making trial results of innovative medicines public  within one year of study completion, regardless of outcome. As time has passed, we only continue to strengthen our commitment to data transparency.
                                </p>

                                            <div className="read-more external" onClick={() => self.onOtherListBtnClick("https://www.novartis.com/sites/www.novartis.com/files/leaders-in-clinical-trial-data-transparency.pdf")}>
                                                Click here to learn more  <span></span>
                                            </div>

                                        </div>

                                        <div className="img-text-left pull-right device-hide">
                                            <img src={Clinical_Trial_Transparency} alt={""} />
                                        </div>

                                        <div className="clear"></div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="line"></div>

                        <div className="section">
                            <div className="container">
                                <div className="three-box">
                                    <div className="three-box-item one pull-left">
                                        <div className="title">Drug Development Process</div>

                                        <div className="video-img">
                                            <div className="play-icon" onClick={() => self.onVideoPopClick(true)}> </div>
                                        </div>

                                    </div>

                                    <div className="three-box-item two pull-left">
                                        <div className="title">What are Clinical Trials? </div>
                                        <p className="para">Clinical trials are scientific studies conducted to find better ways to prevent, screen for, diagnose, or treat disease.</p>
                                        <div className="read-more external" onClick={() => self.onOtherListBtnClick("https://www.novartisclinicaltrials.com/TrialConnectWeb/whatisclinicaltrials.nov")}>
                                            Click here to learn more  <span></span>
                                        </div>

                                    </div>
                                    <div className="three-box-item three pull-left">
                                        <div className="title">Clinical Trial Benefits and Risks </div>
                                        <p className="para">The potential benefits and risks of participating in a clinical trial should be discussed with your doctor.</p>
                                        <div className="read-more external" onClick={() => self.onOtherListBtnClick("https://www.novartisclinicaltrials.com/TrialConnectWeb/benefits.nov")}>
                                            Click here to learn more  <span></span>
                                        </div>

                                    </div>
                                    <div className="three-box-item four pull-left">
                                        <div className="title">Our Focus/Patient and Caregivers </div>
                                        <p className="para">Novartis works with the patient community around the world to discover new ways to improve and extend people’s lives.</p>
                                        <div className="read-more external" onClick={() => self.onOtherListBtnClick("https://www.novartis.com/our-focus/patients-caregivers")}>
                                            Click here to learn more  <span></span>
                                        </div>

                                    </div>
                                    <div className="three-box-item five pull-left">
                                        <div className="title">ClinicalTrials.gov </div>
                                        <p className="para">ClinicalTrials.gov is a registry and results database of publicly and privately supported clinical studies of human participants conducted around the world.</p>
                                        <div className="read-more external" onClick={() => self.onContinueBtnClick(true, "https://clinicaltrials.gov/")}> Click here to learn more <span></span></div>

                                    </div>
                                    <div className="three-box-item six pull-left">
                                        <div className="title">EU Clinical Trials Register </div>
                                        <p className="para">The EU Clinical trials register contains information on interventional clinical trials on medicines conducted in the European Union (EU).</p>
                                        <div className="read-more external" onClick={() => self.onContinueBtnClick(true, "https://www.clinicaltrialsregister.eu/")}> Click here to learn more <span></span></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {showVideoPop && <div className="video-pop">
                    <div className="video-close" onClick={() => self.onVideoPopClick(false)}>×</div>
                    <div className="video-cont" id="videoFrame">

                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3Gl0gAcW8rw?autoplay=1" title="Drug Development Process" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                    </div>
                </div>}

                {showContinuePop && <div className="popup" id="homePopup">
                    <div className="popup-overlay"></div>
                    <div className="popup-center">
                        <div className="popup-body">

                            <div className="popup-close pull-right" onClick={() => self.onContinueBtnClick(false)}></div>

                            <div className="popup-inner">
                                <div className="popup-logo"><img src={novartislogo} alt={""} /> </div>
                                <p className="popup-para">You are now leaving the novctrd.com. This link will take you to a website to which our <span onClick={() => self.onProvacyBtnClick()}> Privacy policy </span> does not apply. You are solely responsible for your interactions with that website.
                        </p>

                                <div className="popup-btn-sec">
                                    <div className="read-more right-gap pull-left" onClick={() => self.onMoveContinueBtnClick(false)}>Continue
                                    </div>
                                    <div className="read-more pull-left hide-pop" onClick={() => self.onContinueBtnClick(false)}>Cancel</div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        )
    }
}

export default withRouter(Home);