import React from "react";
import './Footer.scss';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    onClickTabBtn = (screentype) => {
        window.location.hash = `/${screentype}`;
    }
    render() {
        return (
            <div className="footer section">
                <div className="container">
                    <div className="footer1">
                        <p className="para">This site is designed to be compatible with modern internet browsers such as IE 9 or 10, Google Chrome, Safari and Mozilla Firefox. It is not optimized for use with older browsers.
                    </p>
                    </div>
                    <div className="footer2">
                        <div className="copyright pull-left">© 2020 Novartis AG</div>
                        <div className="foo-center">This site is intended for a global audience</div>
                        <ul className="pull-right">
                            <li onClick={() => { this.onClickTabBtn('termsofuse') }}>Terms of use</li>
                            <li className="line-hor"></li>
                            <li onClick={() => { this.onClickTabBtn('privacypolicy') }}>Privacy policy</li>
                            <li className="line-hor"></li>
                            <li id="cookie-settings-button" className="menu__link ot-sdk-show-settings">  Cookie Settings  </li>
                            <div className="clear"></div>
                        </ul>
                        <div className="clear"></div>
                    </div>
                </div>
            </div >
        )
    }
}

export default (Footer);