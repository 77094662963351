import React from "react";
import { withRouter, Link } from 'react-router-dom'
import Header from '../Header/Header';

class Pagenotfound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="main">
                <Header activeTabName={'pagenotfound'} />
                <div id="mainSection">
                    <div id="page">
                        <div className="section pagenotfound">
                            <div className="container">
                                <h1>Page Not Found</h1>
                                <p>We're sorry, but the page you're looking for may have been moved or deleted.</p>
                                <h2><Link to='/index'>Back to Home Page</Link></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Pagenotfound);
