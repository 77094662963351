import React, { Component } from 'react';
import {
  Route, Switch
} from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import Home from './components/Home/Home';
import Terms from './components/Terms/Terms';
import Termsofuse from './components/Termsofuse/Termsofuse';
import Privacypolicy from './components/Privacypolicy/Privacypolicy';
import Clinicaltrial from './components/Clinicaltrial/Clinicaltrial';
import Trialsummaries from './components/Trialsummaries/Trialsummaries';
import Productlist from './components/Productlist/Productlist';
import NovartisProductLibrary from './components/NovartisProductLibrary/NovartisProductLibrary';
import Pagenotfound from './components/Pagenotfound/Pagenotfound';
import Footer from './components/Footer/Footer';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfoDetails: null,
    }
  }
  componentDidMount = () => {
  
  }

  render() {
    return (
      <div className="App">
        <HashRouter>         
          <Switch>
            <Route path="/" exact component={() => <Home />} />
            <Route path="/index" exact component={() => <Home  />} />
            <Route path="/terms" exact component={() => <Terms  />} />
            <Route path="/termsofuse" exact component={() => <Termsofuse  />} />
            <Route path="/privacypolicy" exact component={() => <Privacypolicy  />} />
            <Route path="/clinicaltrialresults" component={() => <Clinicaltrial   />} />
            <Route path="/trialsummariesforpatients" component={() => <Trialsummaries  />} />
            <Route path="/product" component={() => <Productlist  />} />
            <Route path="/NovartisProductLibrary" exact component={() => <NovartisProductLibrary />} />
            <Route path="*" exact component={() => <Pagenotfound />} />
          </Switch>
        </HashRouter>
        <Footer />
      </div>
    )
  }
}
export default App;
