import React from 'react';
import { scroller } from "react-scroll";

export const submitLoader = props => {
    return (
        <div className={(() => {
            switch (props.class_name) {
                case 'error':
                    return 'loaderMsg error'
                case 'success':
                    return 'loaderMsg success'
                case 'nodata':
                    return 'loaderMsg nodata'
                case 'loading':
                    return 'loaderMsg loader'
                default:
                    return 'loaderMsg loader'
            }
        })()}>
            <div className="msg-center">
                <div className="msg-icon"></div>
                <span>{props.api_message}</span>
            </div>
        </div>
    );
};




export const goToViolation = (id) => {
    return (
        setTimeout(() => {
            scroller.scrollTo((id), {
                duration: 800,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: ((window.innerWidth > 1024) ? -150 : -60)
            });
        }, 200)
    )
}