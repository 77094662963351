import React from "react";
import { withRouter } from 'react-router-dom'
import Select from 'react-select';
import Header from '../Header/Header';
import * as Commonapirequest from '../Commonapirequest/Commonapirequest';
import * as Common from '../Common/Common';
import './NovartisProductLibrary.scss';

let self;
class NovartisProductLibrary extends React.Component {
    constructor(props) {
        super(props);
        self = this;
        this.state = {
            medicalConditionCount: 0,
            resultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            productLibraryData: [],
            productDummyLibraryData: [],
            selectCategoryValue: null,
            selectedCategoryOptions: [
                { value: "Product Code", label: "Product Code", id: "ProductCode" },
                { value: "Generic Name", label: "Generic Name", id: "GenericName" },
                { value: "Medical Condition Studied", label: "Medical Condition Studied", id: "MedicalCondition" }
            ],
            selectSearchValue: null,
            selectedSearchOptions: [],
            searchValueError: '',

            productCodeSearchFieldOptions: [],
            genericNameSearchFieldOptions: [],
            medicalConditionSearchFieldOptions: [],

            fixedHeader: false,
            searchNoDataState: false,
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        self.productLibraryDataLoad();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop

        if (self.state.medicalConditionCount > 15 && winScroll > 211) {
            self.setState({ fixedHeader: true })
        } else {
            self.setState({ fixedHeader: false })
        }
    }

    productLibraryDataLoad = async () => {
        self.setState({
            resultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            productLibraryData: []
        })

        let setUrl = `product/products/mcs?k=${Commonapirequest.timeStamp()}`


        let clinicalListData = {
            url: setUrl,
            data: null,
            requestMethod: 'get',
        }
        let productLibrary = await Commonapirequest.makeApiRequest(clinicalListData);

        if (productLibrary) {
            if (productLibrary.status === 200) {

                let pushData = [];
                let productListData = [];
                let medicalConditionListData = [];
                let genericNameListData = [];
                let productArray = [];
                let genericArray = [];
                let medicalArray = [];
                for (const [key, value] of Object.entries(productLibrary.data)) {
                    productArray.push(key);
                    for (const [key1, value1] of Object.entries(value)) {
                        if (key1 !== '--') {
                            genericArray.push(key1);
                        }
                        for (var i = 0; i < value1.length; i++) {
                            medicalArray.push(value1[i])
                        }

                        let obj = {
                            productCode: key,
                            genericDrugName: key1,
                            medicalConditions: value1
                        }
                        pushData.push(obj);
                    }
                }

                let medicalUniqueNames = self.getUnique(medicalArray);
                for (var u = 0; u < medicalUniqueNames.length; u++) {
                    medicalConditionListData.push({ value: medicalUniqueNames[u], label: medicalUniqueNames[u] })
                }
                let uniqueGeneric = self.getUnique(genericArray);
                for (var g = 0; g < uniqueGeneric.length; g++) {
                    genericNameListData.push({ value: uniqueGeneric[g], label: uniqueGeneric[g] })
                }

                let uniqueProduct = self.getUnique(productArray);
                for (var p = 0; p < uniqueProduct.length; p++) {
                    productListData.push({ value: uniqueProduct[p], label: uniqueProduct[p] })
                }

                let count = 0;
                for (var h = 0; h < pushData.length; h++) {
                    count += (pushData[h].medicalConditions.length)
                }
                self.setState({
                    medicalConditionCount: count,
                    productLibraryData: pushData,
                    productDummyLibraryData: pushData,
                    productCodeSearchFieldOptions: productListData,
                    medicalConditionSearchFieldOptions: medicalConditionListData,
                    genericNameSearchFieldOptions: genericNameListData,
                    resultsLoader: { class_name: 'loading', api_message: '', showloader: false }
                })

            } else {
                self.setState({
                    resultsLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true }
                })
            }

        } else {
            self.setState({
                resultsLoader: { class_name: 'error', api_message: 'Network error', showloader: true }
            })
        }
    }


    getUnique = (array) => {
        var uniqueArray = [];

        // Loop through array values
        for (var i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    filterDataLoad = () => {
        self.setState({
            resultsLoader: { class_name: 'loading', api_message: '', showloader: true },
            productLibraryData: []
        })

        let filterArray = [];

        if (self.state.selectCategoryValue.label === 'Product Code') {
            for (var i = 0; i < self.state.productDummyLibraryData.length; i++) {
                if (self.state.productDummyLibraryData[i].productCode === self.state.selectSearchValue.label) {
                    filterArray.push(self.state.productDummyLibraryData[i])
                }
            }
        } else if (self.state.selectCategoryValue.label === 'Generic Name') {
            for (var a = 0; a < self.state.productDummyLibraryData.length; a++) {
                if (self.state.productDummyLibraryData[a].genericDrugName === self.state.selectSearchValue.label) {
                    filterArray.push(self.state.productDummyLibraryData[a])
                }
            }
        } else if (self.state.selectCategoryValue.label === 'Medical Condition Studied') {
            for (var b = 0; b < self.state.productDummyLibraryData.length; b++) {
                for (var j = 0; j < self.state.productDummyLibraryData[b].medicalConditions.length; j++) {
                    if (self.state.productDummyLibraryData[b].medicalConditions[j] === self.state.selectSearchValue.label) {
                        filterArray.push(self.state.productDummyLibraryData[b])
                    }
                }
            }
        }
        if (filterArray.length > 0) {
            let count = 0;
            for (var t = 0; t < filterArray.length; t++) {
                count += filterArray[t].medicalConditions.length
            }

            setTimeout(() => {
                self.setState({
                    medicalConditionCount: count,
                    productLibraryData: filterArray,
                    resultsLoader: { class_name: 'loading', api_message: '', showloader: false },
                })
            }, 100);
        } else {
            setTimeout(() => {
                self.setState({
                    productLibraryData: [],
                    resultsLoader: { class_name: 'nodata', api_message: 'No Data Available', showloader: true },
                })
            }, 100);
        }

    }

    onLoadingFullData = () => {
        self.setState({
            medicalConditionCount: 0,
            productLibraryData: [],
            resultsLoader: { class_name: 'loading', api_message: '', showloader: true }
        })
        let count = 0;
        for (var j = 0; j < self.state.productDummyLibraryData.length; j++) {
            count += self.state.productDummyLibraryData[j].medicalConditions.length
        }
        setTimeout(() => {
            self.setState({
                medicalConditionCount: count,
                productLibraryData: self.state.productDummyLibraryData,
                resultsLoader: { class_name: 'loading', api_message: '', showloader: false }
            })
        }, 100);
    }

    handleCategoryChange = (newValue) => {
        self.setState({
            searchValueError: '',
            selectCategoryValue: newValue,
            selectSearchValue: null,
            selectedSearchOptions: []
        });
        if (self.state.selectSearchValue !== null) {
            self.onLoadingFullData();
        }
    }

    handleInputChange = () => {
        if (self.state.selectSearchValue === null) {
            self.setState({
                selectedSearchOptions: [],
                searchNoDataState: false
            })
        }
    }

    onInputKeyDown = (e) => {
        let abc = e.target
        setTimeout(() => {
            let searchText = abc.value
            if ((searchText.length === 0 || searchText.length === 1 || searchText.length === 2)) {
                self.setState({ selectedSearchOptions: [] })
            }
            if (searchText.length >= 3) {
                if (self.state.selectCategoryValue.label === 'Product Code') {
                    let noDataShow = self.oncommonNoData(self.state.productCodeSearchFieldOptions, searchText)

                    self.setState({
                        selectedSearchOptions: self.state.productCodeSearchFieldOptions,
                        searchNoDataState: noDataShow
                    })
                } else if (self.state.selectCategoryValue.label === 'Generic Name') {
                    let noDataShow = self.oncommonNoData(self.state.genericNameSearchFieldOptions, searchText)

                    self.setState({
                        selectedSearchOptions: self.state.genericNameSearchFieldOptions,
                        searchNoDataState: noDataShow
                    })
                } else if (self.state.selectCategoryValue.label === 'Medical Condition Studied') {
                    let noDataShow = self.oncommonNoData(self.state.medicalConditionSearchFieldOptions, searchText)

                    self.setState({
                        selectedSearchOptions: self.state.medicalConditionSearchFieldOptions,
                        searchNoDataState: noDataShow
                    })
                }
            } else {
                self.setState({ searchNoDataState: false })
            }
        }, 1);
        self.setState({
            searchValueError: '',
            selectSearchValue: null
        });

    }

    oncommonNoData = (data, searchText) => {
        let noData = true;
        for (var i = 0; i < data.length; i++) {
            let str = (data[i].label)
            let result = str.includes(searchText.trim());
            if (result) {
                noData = false
            }
        }
        return noData
    }

    handleSearchChange = (newValue) => {
        let findSearchOptions = []
        if (self.state.selectCategoryValue.label === "Product Code") {
            findSearchOptions = self.state.productCodeSearchFieldOptions
        } else if (self.state.selectCategoryValue.label === "Generic Name") {
            findSearchOptions = self.state.genericNameSearchFieldOptions
        } else {
            findSearchOptions = self.state.medicalConditionSearchFieldOptions
        }
        let filterArray = [];
        if (newValue !== null) {
            for (var i = 0; i < findSearchOptions.length; i++) {
                let str = findSearchOptions[i].label;
                let result = str.includes(newValue.label.trim());
                if (result) {
                    filterArray.push(findSearchOptions[i])
                }
            }
        } else {
            self.onLoadingFullData();
        }
        self.setState({
            selectedSearchOptions: filterArray,
            selectSearchValue: newValue,
            searchValueError: ''
        })
    }

    onSearchIconClick = () => {
        if (self.state.selectCategoryValue === null) {
            self.setState({ searchValueError: "category" })
        } else if (self.state.selectSearchValue === null) {
            self.setState({ searchValueError: "search" })
        } else {
            self.filterDataLoad();
        }
    }

    render() {
        const {
            resultsLoader,
            productLibraryData,
            selectCategoryValue,
            selectedCategoryOptions,
            selectSearchValue,
            selectedSearchOptions,
            searchValueError,
            fixedHeader,
            searchNoDataState,
        } = self.state

        return (
            <div className="main">
                <Header activeTabName={'NovartisProductLibrary'} />
                <div id="mainSection">
                    <div id="page">
                        <div className="section text-area">
                            <div className="container">
                                <h1>Novartis Clinical Trial Results Product Library</h1>

                                <div className="search-form library" style={{ zIndex: '2' }}>
                                    <div className="item select-item category-item" style={{ zIndex: 1 }}>
                                        <Select
                                            value={selectCategoryValue}
                                            onChange={this.handleCategoryChange}
                                            options={selectedCategoryOptions}
                                            className="select-box"
                                            placeholder={"Select Category"}
                                            isSearchable={false}
                                            isClearable={true}
                                        />

                                        {searchValueError === "category" && <div className="minimum-error"> Please select Category  </div>}
                                    </div>
                                    <div className="item select-item input-item search-item library-search">
                                        <Select
                                            value={selectSearchValue}
                                            onChange={this.handleSearchChange}
                                            onKeyDown={this.onInputKeyDown}
                                            onInputChange={this.handleInputChange.bind(this)}
                                            options={selectedSearchOptions}
                                            className="select-box"
                                            placeholder={selectCategoryValue !== null ? (selectCategoryValue.label === "Medical Condition Studied" ? "Search Medical Condition Studied" : selectCategoryValue.label === "Product Code" ? "Search Product Code" : selectCategoryValue.label === "Generic Name" ? "Search Generic Name" : "Search Filter") : 'Search Filter'}

                                            searchable
                                            isClearable={true}
                                            isDisabled={selectCategoryValue === null ? true : false}
                                            autoBlur
                                            noOptionsMessage={searchNoDataState ? () => { } : () => null}
                                            openOnFocus
                                        />
                                        {searchValueError === "search" && <div className="minimum-error"> Please enter at least 3 characters </div>}
                                    </div>
                                    <div className="item icon-item">
                                        <div className="icon" onClick={() => self.onSearchIconClick()}></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>



                                <div className="library-div">
                                    {resultsLoader.showloader && Common.submitLoader(resultsLoader)}

                                    {productLibraryData.length > 0 && <div className="pagination-block">
                                        <div className={fixedHeader ? "pagination-head fixed" : "pagination-head"}>
                                            <ul>
                                                <li style={{ width: "25%" }}>Product Code </li>
                                                <li style={{ width: "25%" }}>Generic Name </li>
                                                <li style={{ width: "50%" }}>Medical Condition Studied </li>
                                                <div className="clear"></div>
                                            </ul>
                                        </div>
                                        <div className={fixedHeader ? "pagination-body fixed" : "pagination-body"}>
                                            {productLibraryData.length > 0 && <div>
                                                {productLibraryData.map((item, index) => (
                                                    <ul key={index}>
                                                        <li style={{ width: "25%" }}>{item.productCode} </li>
                                                        <li style={{ width: "25%" }}>{item.genericDrugName} </li>
                                                        <li style={{ width: "50%" }}>
                                                            {item.medicalConditions.length > 0 && <ul>
                                                                {item.medicalConditions.map((medicalItem, medicalIndex) => (
                                                                    <li key={medicalIndex}>{medicalItem}</li>
                                                                ))}
                                                            </ul>}
                                                        </li>
                                                        <div className="clear"></div>
                                                    </ul>
                                                ))}
                                            </div>}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NovartisProductLibrary);