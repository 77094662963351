
import React from "react";
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import { Steps } from 'intro.js-react';
import * as Commonapirequest from '../../components/Commonapirequest/Commonapirequest';
import * as Common from '../../components/Common/Common';

import 'intro.js/introjs.css';

import novartislogo from '../../assets/novartis-logo.svg';
import './Header.scss';
let self;
class Header extends React.Component {
    constructor(props) {
        super(props);
        self = this;
        this.state = {
            googleDiv: false,
            surveyPopShow: false,
            startIntroJs: false,
            deviceNav: false,
            stepsEnabled: true,
            initialStep: 0,
            steps: [
                {
                    element: ".firstIntro",
                    intro: "Click here to view the Clinical Trial Results"
                },
                {
                    element: ".secondIntro",
                    intro: "Click here to view the Trial Summaries for patients"
                }
            ],
            disabledGoogle: false,
            suveyGoogleDiv: false,
            latitudeValue: null,
            longitudeValue: null,
            userSurveyDataArray: [],
            countryDetails: {
                city: null,
                country: null,
                countryCode: null,
                state: null,
                ip: null,
                region: null,
            },
            surveyLoader: { class_name: 'loading', api_message: '', showloader: false },
            otherCheck: false,
            surveyTitleObj: {},
            csrToken: null
        }
    }

    componentDidMount() {

        if (window.location.hash === '#/' || window.location.hash === '#/index') {
            if (localStorage.getItem("designationId") === undefined || localStorage.getItem("designationId") === null || localStorage.getItem("designationId") === "") {
                self.setState({ surveyPopShow: true, disabledGoogle: true }, () => {
                    document.body.classList.remove('google_translate');
                    self.findLatitudeAndLongitude();
                    self.userSurveyTitle();
                    self.userSurveyDesignations();
                })
                document.body.classList.add('bodyActive');
            }
        }

        window.addEventListener("resize", this.windowResize.bind(this));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.FeedbackPop) {
            self.setState({ googleDiv: false, disabledGoogle: true });
        } else {
            self.setState({ disabledGoogle: false })
        }
        if (nextProps.feedbackGoogleClick) {
            document.getElementById("google_translate_element").addEventListener('change', function (e) {
                self.setState({ suveyGoogleDiv: false });
            })
        }
    }
    windowResize = () => {
        if (window.innerWidth > 1000) {
            self.setState({ deviceNav: false })
        }
    }

    onClickTabBtn = (screentype) => {
        localStorage.setItem("headTab", screentype)
        if (sessionStorage.getItem("terms") === null) {
            this.props.history.push(`/terms`);
            return false;
        } else {
            if (this.props.activeTabName !== screentype) {
                this.props.history.push(`/${screentype}`);
                return false;
            }
        }
    }
    onHeaderLogoClick = () => {
        this.props.history.push(`/index`);
        window.location.hash = '#/index';
        return false;
    }

    onSurveyRadioClick = (text) => {
        let a = self.state.userSurveyDataArray;

        for (var i = 0; i < a.length; i++) {
            a[i].click = false;
        }
        a[text].click = (a[text].click === false ? true : false);

        self.setState({ userSurveyDataArray: a });
    }


    findLatitudeAndLongitude = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
        }

        function showPosition(position) {
            self.setState({ latitudeValue: position.coords.latitude });
            self.setState({ longitudeValue: position.coords.longitude });
            self.getCountryName();
        }
    }

    getCountryName = () => {
        let url = 'https://ipapi.co/json/';

        axios({
            url: url,
            method: 'GET',
            data: null,
        }).then((location) => {
            self.setState({
                countryDetails: {
                    city: location.data.city,
                    country: location.data.country_name,
                    countryCode: location.data.country_code,
                    ip: location.data.ip,
                    region: location.data.region
                }
            })
        }).catch((error) => {

        })

    }


    userSurveyTitle = async () => {
        self.setState({ surveyTitleObj: {} })
        let passingObj = {
            url: `usersurvey/feedbacktitle?titleType=USER_SURVEY&k=${Commonapirequest.timeStamp()}`,
            data: null,
            requestMethod: 'get',
        }
        let title = await Commonapirequest.makeApiRequest(passingObj);
        if (title) {
            if (title.status === 200) {
                self.setState({ surveyTitleObj: title.data })
            }
        }
    }
    userSurveyDesignations = async () => {
        self.setState({
            surveyLoader: { class_name: 'loading', api_message: '', showloader: true },
            userSurveyDataArray: []
        })
        let passingObj = {
            url: `usersurvey/designations?k=${Commonapirequest.timeStamp()}`,
            data: null,
            requestMethod: 'get',
        }
        let surveyDesignations = await Commonapirequest.makeApiRequest(passingObj);

        if (surveyDesignations) {
            self.setState({ csrToken: surveyDesignations.headers['x-csrf-token'] });
            if (surveyDesignations.status === 200) {
                if (surveyDesignations.data.length > 0) {
                    let surveryNames = surveyDesignations.data
                    for (var i = 0; i < surveryNames.length; i++) {
                        surveryNames[i].click = false;
                    }
                    surveryNames[0].click = true;
                    self.setState({
                        userSurveyDataArray: surveryNames,
                        surveyLoader: { class_name: 'loading', api_message: '', showloader: false }
                    });
                } else {
                    localStorage.setItem("survey", true);
                    self.setState({ surveyLoader: { class_name: 'nodata', api_message: 'No Data Available', showloader: true } });
                    setTimeout(() => {
                        self.onUserSurveyOk();
                    }, 3000);
                }
            } else {
                self.setState({ surveyLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true } })
                setTimeout(() => {
                    self.onUserSurveyOk();
                }, 3000);
            }
        } else {
            self.setState({ surveyLoader: { class_name: 'error', api_message: 'Network error', showloader: true } })
            setTimeout(() => {
                self.onUserSurveyOk();
            }, 3000);
        }
    }

    onSurveyDoneBtnClick = async () => {

        let designation = '';
        let designationId = '';
        for (var i = 0; i < self.state.userSurveyDataArray.length; i++) {
            if (self.state.userSurveyDataArray[i].click) {
                designation = self.state.userSurveyDataArray[i].name
                designationId = self.state.userSurveyDataArray[i].id
            }
        }
        self.setState({ surveyLoader: { class_name: 'loading', api_message: '', showloader: true } })

        let userSurvey = {
            city: self.state.countryDetails.city,
            country: self.state.countryDetails.country,
            countryCode: self.state.countryDetails.countryCode,
            designation: designation,
            designationId: designationId,
            latitude: self.state.latitudeValue,
            longitude: self.state.longitudeValue,
            state: self.state.countryDetails.state
        }

        let passingObj = {
            url: 'usersurvey/save',
            data: userSurvey,
            requestMethod: 'post',
            token: self.state.csrToken
        }
        let surveySave = await Commonapirequest.makeApiRequest(passingObj);

        if (surveySave) {
            if (surveySave.status === 200) {
                self.setState({ surveyLoader: { class_name: 'success', api_message: 'Successfully submited', showloader: true } })
                setTimeout(() => {
                    self.onUserSurveyOk();
                }, 3000);

                localStorage.setItem("designationId", surveySave.data);
            } else {
                self.setState({ surveyLoader: { class_name: 'error', api_message: 'Something went wrong', showloader: true } })
                setTimeout(() => {
                    self.onUserSurveyOk();
                }, 3000);
            }
        } else {
            self.setState({ surveyLoader: { class_name: 'error', api_message: 'Network error', showloader: true } })
            setTimeout(() => {
                self.onUserSurveyOk();
            }, 3000);
        }

    }
    onUserSurveyOk = () => {
        document.body.classList.remove('google');

        if (window.innerWidth > 1000) {
            self.setState({ startIntroJs: true })
            document.body.classList.add('introActive');
        } else {
            document.body.classList.remove('bodyActive');
        }

        self.setState({
            surveyPopShow: false,
            disabledGoogle: false,
            suveyGoogleDiv: false,
            surveyLoader: { class_name: 'loading', api_message: '', showloader: false }
        })
    }

    onGoogleBtnClick = () => {
        //self.setState({ googleDiv: !self.state.googleDiv })
        document.body.classList.toggle('google_translate');
    }

    onSurveyGoogleClick = () => {
        self.setState({ suveyGoogleDiv: self.state.suveyGoogleDiv === true ? false : true }, () => {

        })

        if (self.state.suveyGoogleDiv) {
            document.body.classList.remove('google');
        } else {
            document.body.classList.add('google');
        }

        document.getElementById("google_translate_element").addEventListener('change', function (e) {
            self.setState({ suveyGoogleDiv: false })
            document.body.classList.remove('google');
        })

    }

    onDeviceMenuBtnClick = () => {
        self.setState({ deviceNav: !self.state.deviceNav })
    }

    onExit = () => {
        self.setState({ startIntroJs: false });
        document.body.classList.remove('bodyActive');
        document.body.classList.remove('introActive');
    }


    render() {
        const {
            surveyPopShow,
            stepsEnabled,
            steps,
            initialStep,
            startIntroJs,
            googleDiv,
            disabledGoogle,
            suveyGoogleDiv,
            surveyLoader,
            userSurveyDataArray,
            otherCheck,
            surveyTitleObj,
            deviceNav
        } = this.state
        const { activeTabName } = this.props

        return (
            <div className="main-header">

                {startIntroJs && <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onExit={this.onExit}
                />}
                <div className={googleDiv ? "header google" : suveyGoogleDiv ? "header survey" : "header"}>

                    <div className="container">

                        <div className="logo pull-left" onClick={() => self.onHeaderLogoClick()}>
                            <img src={novartislogo} alt={""} />
                        </div>
                        <div className={disabledGoogle ? "google-translate-btn pull-right opacity" : "google-translate-btn pull-right"} id="headerGoogle" title="Google Translate" onClick={() => this.onGoogleBtnClick()}> Google Translate
                    <span></span>
                        </div>

                        <div className="navigation pull-right">
                            <ul>
                                <li className={activeTabName === 'clinicaltrialresults' ? 'clinicaltrialresults active' : 'clinicaltrialresults'} onClick={() => { this.onClickTabBtn('clinicaltrialresults') }}>
                                    <p className="firstIntro">Clinical Trial Results </p>
                                </li>
                                <li className={activeTabName === 'trialsummariesforpatients' ? 'trialsummariesforpatients active' : 'trialsummariesforpatients'} onClick={() => { this.onClickTabBtn('trialsummariesforpatients') }}>
                                    <p className="secondIntro">Trial Summaries for Patients </p>
                                </li>
                                <div className="clear"></div>
                            </ul>
                        </div>

                        <div className={deviceNav ? "device-menu active hide" : "device-menu hide"} onClick={() => self.onDeviceMenuBtnClick()}><span></span></div>
                        <div className="clear"></div>

                    </div>

                    {deviceNav &&
                        <div className={googleDiv ? "device-nav google" : "device-nav"}> <ul>
                            <li className={activeTabName === 'clinicaltrialresults' ? 'clinicaltrialresults active' : 'clinicaltrialresults'} onClick={() => { this.onClickTabBtn('clinicaltrialresults') }}>
                                Clinical Trial Results
                                </li>
                            <li className={activeTabName === 'trialsummariesforpatients' ? 'trialsummariesforpatients active' : 'trialsummariesforpatients'} onClick={() => { this.onClickTabBtn('trialsummariesforpatients') }}>
                                Trial Summaries for Patients
                                </li>

                        </ul>
                        </div>}
                    {deviceNav && <div className={googleDiv ? "device-layer google" : "device-layer"} onClick={() => self.onDeviceMenuBtnClick()}></div>}

                </div>
                <div className={(surveyPopShow === false && googleDiv) ? ((activeTabName === "home" && !startIntroJs) ? "dummy-header google banner-img" : "dummy-header google") : (surveyPopShow === false && suveyGoogleDiv) ? ((activeTabName === "home" && !startIntroJs) ? "dummy-header survey banner-img" : "dummy-header survey") : ((activeTabName === "home" && !startIntroJs) ? "dummy-header banner-img" : "dummy-header")}></div>

                {surveyPopShow && <div className="survey-pop">
                    <div className="google-translate-btn" id="surveyGoogle" title="Google Translate" onClick={() => self.onSurveyGoogleClick()}> Google Translate
                    <span></span>
                    </div>

                    <div className="center">
                        {surveyLoader.showloader && Common.submitLoader(surveyLoader)}

                        <div className="survey-title">
                            <div className="fetitle"> {(surveyTitleObj.name === undefined || surveyTitleObj.name === null) ? 'Novartis User Survey' : surveyTitleObj.name} </div>
                            <p>{(surveyTitleObj.title === undefined || surveyTitleObj.title === null) ? 'Use Google Translate located in menus, if you need to translate the language.' : surveyTitleObj.title} </p>
                        </div>
                        <div className="survey-cont">
                            <div className="form">
                                <div className="form-item">
                                    <h1>{(surveyTitleObj.question === undefined || surveyTitleObj.question === null) ? 'Which of the following are you?' : surveyTitleObj.question}</h1>

                                    {userSurveyDataArray.length > 0 && <div className="radio-box">

                                        {userSurveyDataArray.map((surveyItem, index) => (
                                            <div key={index} className={surveyItem.click ? "radio-item active" : "radio-item"}  onClick={() => self.onSurveyRadioClick(index)}>{surveyItem.name}
                                                <span className="checkmark"></span>
                                            </div>
                                        ))}

                                        {otherCheck && <input id="start-textarea" placeholder="" />}
                                    </div>}
                                </div>
                            </div>

                            <div className="buttons-section">
                                <div className="read-more" onClick={() => self.onSurveyDoneBtnClick()}>Done</div>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        )
    }
}
export default withRouter(Header);